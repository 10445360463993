// Translations object with all text content
export const translations = {
  pt: {
    // Header buttons
    language: 'EN',
    controls: 'Controles',
    currentParcel: 'Terreno #',
    // Controls modal
    controlsTitle: 'Controles da Spaceship',
    controlsText: `
      W - Mover para frente
      S - Mover para trás
      A - Mover para esquerda
      D - Mover para direita
      Espaço - Subir
      Control - Descer
      Shift - Movimento 2x mais rápido
      R - Reposicionar nave
      Mouse (Arrastar) - Girar câmera
      Scroll - Zoom
    `,
    // Search
    searchPlaceholder: 'Terreno (0-281)',
    searchButton: 'Ir',
    // Chat
    onlineUsers: 'Usuários Online',
    connected: 'Conectado',
    connecting: 'Conectando...',
    disconnected: 'Desconectado',
    messageInputPlaceholder: 'Digite sua mensagem...',
    sendButton: 'Enviar',
    you: 'Você',
    // Block info
    block: 'Terreno',
    size: 'Tamanho'
  },
  en: {
    // Header buttons
    language: 'PT',
    controls: 'Controls',
    currentParcel: 'Parcel #',
    noParcel: 'Flying outside parcels',
    // Controls modal
    controlsTitle: 'Spaceship Controls',
    controlsText: `
      W - Move forward
      S - Move backward
      A - Move left
      D - Move right
      Space - Move up
      Control - Move down
      Shift - Move 2x faster
      R - Reset spaceship position
      Mouse (Drag) - Rotate camera
      Scroll - Zoom
    `,
    // Search
    searchPlaceholder: 'Parcel (0-281)',
    searchButton: 'Go',
    // Chat
    onlineUsers: 'Online Users',
    connected: 'Connected',
    connecting: 'Connecting...',
    disconnected: 'Disconnected',
    messageInputPlaceholder: 'Type your message...',
    sendButton: 'Send',
    you: 'You',
    // Block info
    block: 'Parcel',
    size: 'Size'
  }
} 