import { useEffect, useState, useRef, useCallback } from 'react';
import type { FormEvent } from 'react';
import Peer, { DataConnection } from 'peerjs';

// Interface para as mensagens
interface Message {
  from: string;
  content: string;
  timestamp: number;
}

// Interface para os peers conectados
interface ConnectedPeer {
  id: string;
  connection: DataConnection;
}

// Interface para os dados das mensagens
interface MessageData {
  type: 'chat-message';
  content: string;
}

// Função para validar dados de mensagem
function isMessageData(data: unknown): data is MessageData {
  return (
    typeof data === 'object' &&
    data !== null &&
    'type' in data &&
    data.type === 'chat-message' &&
    'content' in data &&
    typeof data.content === 'string'
  );
}

// Função para gerar um ID de sessão persistente
function generateSessionId(): string {
  // Tenta recuperar o ID existente do localStorage
  const existingId = localStorage.getItem('chat-session-id');
  if (existingId) {
    console.log("Reusing existing session ID:", existingId);
    return existingId;
  }
  
  // Gera um novo ID se não existir - formato mais simples para debugging
  const newId = `user-${Math.random().toString(36).substring(2, 6)}`;
  console.log("Generated new session ID:", newId);
  localStorage.setItem('chat-session-id', newId);
  return newId;
}

export default function Chat() {
  // Estados para gerenciar o chat
  const [messages, setMessages] = useState<Message[]>([]);
  const [connectedPeers, setConnectedPeers] = useState<ConnectedPeer[]>([]);
  const [messageInput, setMessageInput] = useState<string>('');
  const [myPeerId, setMyPeerId] = useState<string>('');
  const [connectionStatus, setConnectionStatus] = useState<'connecting' | 'connected' | 'disconnected'>('connecting');
  
  // Referências para manter instâncias entre re-renders
  const peerRef = useRef<Peer | null>(null);
  const connectionsRef = useRef<Record<string, DataConnection>>({});
  const serverPort = import.meta.env.PROD ? 443 : (import.meta.env.VITE_SERVER_PORT || 3000);
  const serverHost = import.meta.env.PROD ? 'multiverso-server.onrender.com' : window.location.hostname;
  const secure = import.meta.env.PROD;

  // Referência para o container de mensagens
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  // Função para manipular uma nova conexão de peer
  const handleConnection = useCallback((connection: DataConnection) => {
    // Skip if we already have this connection
    if (connectionsRef.current[connection.peer]?.open) {
      return;
    }
    
    // Set up data handling
    connection.on('data', (data: unknown) => {
      if (isMessageData(data)) {
        const newMessage: Message = {
          from: connection.peer,
          content: data.content,
          timestamp: Date.now()
        };
        setMessages(prev => [...prev, newMessage]);
      }
    });
    
    // Handle connection closed
    connection.on('close', () => {
      delete connectionsRef.current[connection.peer];
      setConnectedPeers(prev => prev.filter(p => p.id !== connection.peer));
    });
    
    // Handle connection errors
    connection.on('error', () => {
      delete connectionsRef.current[connection.peer];
      setConnectedPeers(prev => prev.filter(p => p.id !== connection.peer));
    });
    
    // Store the connection
    connectionsRef.current[connection.peer] = connection;
    
    // Update the UI
    setConnectedPeers(prev => {
      const filtered = prev.filter(p => p.id !== connection.peer);
      return [...filtered, { id: connection.peer, connection }];
    });
  }, []);

  // Função para conectar a um peer específico
  const connectToPeer = useCallback((peerId: string) => {
    if (!peerRef.current?.open || peerId === myPeerId) {
      return;
    }
    
    // Check if we already have a connection
    if (connectionsRef.current[peerId]?.open) {
      return;
    }
    
    try {
      const conn = peerRef.current.connect(peerId);
      conn.on('open', () => handleConnection(conn));
    } catch (err) {
      console.error("Failed to connect to peer:", peerId);
    }
  }, [myPeerId, handleConnection]);

  // Função para buscar peers disponíveis do servidor
  const fetchPeers = useCallback(async () => {
    if (!myPeerId || !peerRef.current?.open) return;
    
    try {
      const protocol = secure ? 'https' : 'http';
      const apiUrl = `${protocol}://${serverHost}/api/peers`;
      const response = await fetch(apiUrl);
      
      if (!response.ok) return;
      
      const peers = await response.json() as string[];
      peers.filter(id => id !== myPeerId).forEach(connectToPeer);
    } catch (err) {
      console.error("Error fetching peers:", err);
    }
  }, [myPeerId, connectToPeer, serverHost, secure]);

  // Inicializa o peer e configura os listeners
  useEffect(() => {
    // Recupera ou gera um ID de sessão
    const sessionId = localStorage.getItem('chat-session-id') || 
      `user-${Math.random().toString(36).substring(2, 6)}`;
    localStorage.setItem('chat-session-id', sessionId);
    
    // Cria a instância do Peer com configuração específica para produção
    const peer = new Peer(sessionId, {
      host: serverHost,
      port: serverPort,
      path: '/peerjs',
      secure: secure, // Usa SSL em produção
      debug: import.meta.env.DEV ? 3 : 0,
      config: {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:stun1.l.google.com:19302' },
          { urls: 'stun:stun2.l.google.com:19302' }
        ]
      }
    });
    
    peerRef.current = peer;
    
    // Configura os event listeners
    peer.on('open', (id) => {
      setMyPeerId(id);
      setConnectionStatus('connected');
      fetchPeers();
    });
    
    peer.on('connection', handleConnection);
    
    peer.on('disconnected', () => {
      setConnectionStatus('disconnected');
      setTimeout(() => peer.reconnect(), 1000);
    });
    
    peer.on('error', () => {
      setConnectionStatus('disconnected');
    });
    
    // Configura o polling de peers
    const peerDiscoveryInterval = setInterval(() => {
      if (peer.open) {
        fetchPeers();
      }
    }, 5000);
    
    // Cleanup
    return () => {
      clearInterval(peerDiscoveryInterval);
      peer.destroy();
    };
  }, [handleConnection, fetchPeers, serverHost, serverPort, secure]);

  // Função para rolar para a última mensagem
  const scrollToBottom = useCallback(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, []);

  // Efeito para rolar para baixo quando novas mensagens chegam
  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  // Função para enviar mensagem
  const sendMessage = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (!messageInput.trim() || !myPeerId) return;
    
    const messageData: MessageData = {
      type: 'chat-message',
      content: messageInput,
    };
    
    // Envia para todos os peers conectados
    Object.values(connectionsRef.current).forEach(conn => {
      if (conn?.open) {
        conn.send(messageData);
      }
    });
    
    // Adiciona a mensagem à lista
    setMessages(prev => [...prev, {
      from: myPeerId,
      content: messageInput,
      timestamp: Date.now()
    }]);
    
    setMessageInput('');
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      backgroundColor: '#000',
      color: '#fff'
    }}
    // Previne que eventos do chat afetem a cena 3D
    onClick={(e) => e.stopPropagation()}
    onKeyDown={(e) => e.stopPropagation()}
    onKeyUp={(e) => e.stopPropagation()}
    onKeyPress={(e) => e.stopPropagation()}
    onMouseDown={(e) => e.stopPropagation()}
    onMouseUp={(e) => e.stopPropagation()}
    onMouseMove={(e) => e.stopPropagation()}
    onWheel={(e) => e.stopPropagation()}>
      <div style={{
        padding: '12px',
        borderBottom: '1px solid rgba(138, 43, 226, 0.3)',
        backgroundColor: 'rgba(138, 43, 226, 0.1)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <h3 style={{ margin: 0, fontSize: '14px' }}>
          Usuários Online ({connectedPeers.length + 1})
        </h3>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          fontSize: '12px'
        }}>
          <div style={{
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: connectionStatus === 'connected' 
              ? '#4CAF50' 
              : connectionStatus === 'connecting' 
                ? '#FFC107' 
                : '#F44336'
          }} />
          {connectionStatus === 'connected' 
            ? 'Conectado' 
            : connectionStatus === 'connecting' 
              ? 'Conectando...' 
              : 'Desconectado'}
        </div>
      </div>

      <div 
        ref={messagesContainerRef}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          padding: '12px',
          overflowY: 'auto',
          scrollBehavior: 'smooth', // Adiciona scroll suave
          // Estiliza a scrollbar para navegadores webkit
          msOverflowStyle: 'none', // Para IE e Edge
          scrollbarWidth: 'thin', // Para Firefox
        }}
      >
        {messages.map((msg, index) => (
          <div
            key={index}
            style={{
              alignSelf: msg.from === myPeerId ? 'flex-end' : 'flex-start',
              maxWidth: '80%',
              backgroundColor: msg.from === myPeerId 
                ? 'rgba(138, 43, 226, 0.3)' 
                : 'rgba(255, 255, 255, 0.1)',
              padding: '8px 12px',
              borderRadius: '12px'
            }}
          >
            <div style={{ fontSize: '12px', opacity: 0.7, marginBottom: '4px' }}>
              {msg.from === myPeerId ? 'Você' : msg.from.split('-')[1] || msg.from}
            </div>
            {msg.content}
          </div>
        ))}
      </div>

      <form 
        onSubmit={sendMessage}
        style={{
          display: 'flex',
          gap: '8px',
          padding: '12px',
          borderTop: '1px solid rgba(138, 43, 226, 0.3)',
          backgroundColor: 'rgba(0, 0, 0, 0.3)'
        }}
        // Previne que o form afete a cena 3D
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        onKeyUp={(e) => e.stopPropagation()}
        onKeyPress={(e) => e.stopPropagation()}
      >
        <input
          type="text"
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          placeholder="Digite sua mensagem..."
          style={{
            flex: 1,
            padding: '8px 12px',
            borderRadius: '20px',
            border: '1px solid rgba(138, 43, 226, 0.3)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#fff',
            outline: 'none'
          }}
          // Previne que o input afete a cena 3D
          onKeyDown={(e) => e.stopPropagation()}
          onKeyUp={(e) => e.stopPropagation()}
          onKeyPress={(e) => e.stopPropagation()}
        />
        <button
          type="submit"
          disabled={!connectionStatus || connectionStatus === 'disconnected'}
          style={{
            padding: '8px 16px',
            borderRadius: '20px',
            border: 'none',
            backgroundColor: connectionStatus === 'connected' 
              ? 'rgba(138, 43, 226, 0.8)' 
              : 'rgba(138, 43, 226, 0.3)',
            color: '#fff',
            cursor: connectionStatus === 'connected' ? 'pointer' : 'not-allowed'
          }}
        >
          Enviar
        </button>
      </form>
    </div>
  );
}

// Estilos inline para o componente
const styles = {
  container: {
    display: 'flex',
    height: '500px',
    maxWidth: '800px',
    margin: '20px auto',
    border: '1px solid #ccc',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  peersList: {
    width: '200px',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRight: '1px solid #ccc',
  },
  peerList: {
    listStyle: 'none',
    padding: 0,
  },
  peerItem: {
    padding: '8px',
    margin: '4px 0',
    backgroundColor: '#fff',
    borderRadius: '4px',
    fontSize: '14px',
  },
  messagesContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as const,
  },
  messages: {
    flex: 1,
    padding: '20px',
    overflowY: 'auto' as const,
  },
  message: {
    margin: '8px 0',
    padding: '8px 12px',
    borderRadius: '8px',
    maxWidth: '70%',
  },
  myMessage: {
    backgroundColor: '#007bff',
    color: '#fff',
    marginLeft: 'auto',
  },
  otherMessage: {
    backgroundColor: '#e9ecef',
    marginRight: 'auto',
  },
  inputForm: {
    display: 'flex',
    padding: '20px',
    borderTop: '1px solid #ccc',
  },
  input: {
    flex: 1,
    padding: '8px 12px',
    marginRight: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '14px',
  },
  button: {
    padding: '8px 16px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
}; 