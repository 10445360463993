import React from 'react';
import { Barrier } from '../utils/CollisionSystem';
import * as THREE from 'three';

interface ParcelBarrierProps {
  barrier: Barrier;
  worldWidth: number;
  worldHeight: number;
}

// Componente para visualizar as barreiras de colisão
const ParcelBarrier: React.FC<ParcelBarrierProps> = ({ barrier, worldWidth, worldHeight }) => {
  // Converter coordenadas do Mondrian para coordenadas do mundo
  const worldX = barrier.position.x - worldWidth / 2;
  const worldZ = barrier.position.y - worldHeight / 2;
  
  // Criar material semi-transparente para a barreira
  const material = new THREE.MeshStandardMaterial({
    color: new THREE.Color('#FF0000'),
    transparent: true,
    opacity: 0.15,
    side: THREE.DoubleSide,
    wireframe: false
  });

  return (
    <group position={[worldX + barrier.size / 2, 0, worldZ + barrier.size / 2]}>
      {/* Cubo que representa exatamente o tamanho da parcela, com altura ajustada */}
      <mesh position={[0, barrier.size / 2, 0]} material={material}>
        <boxGeometry args={[barrier.size, barrier.size, barrier.size]} />
      </mesh>
      
      {/* Contorno no chão para melhor visualização */}
      <mesh position={[0, 0.05, 0]} rotation={[Math.PI / 2, 0, 0]} material={new THREE.MeshBasicMaterial({
        color: new THREE.Color('#FF0000'),
        transparent: true,
        opacity: 0.3,
        side: THREE.DoubleSide
      })}>
        <planeGeometry args={[barrier.size, barrier.size]} />
      </mesh>
    </group>
  );
};

export default ParcelBarrier;
