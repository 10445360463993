import React, { useRef, useMemo } from 'react';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';

interface StarryBackgroundProps {
  count?: number;
  depth?: number;
  size?: number;
  color?: string;
  twinkleSpeed?: number;
}

const StarryBackground: React.FC<StarryBackgroundProps> = ({
  count = 5000,
  depth = 300,
  size = 0.5,
  color = '#ffffff',
  twinkleSpeed = 0.01
}) => {
  const starsRef = useRef<THREE.Points>(null);
  const starsGeometryRef = useRef<THREE.BufferGeometry>(null);
  
  // Criar as estrelas com posições aleatórias
  const [positions, sizes, originalSizes] = useMemo(() => {
    const positions = new Float32Array(count * 3);
    const sizes = new Float32Array(count);
    const originalSizes = new Float32Array(count);
    
    for (let i = 0; i < count; i++) {
      // Posições aleatórias em uma esfera
      const radius = depth * Math.random();
      const theta = 2 * Math.PI * Math.random();
      const phi = Math.acos(2 * Math.random() - 1);
      
      positions[i * 3] = radius * Math.sin(phi) * Math.cos(theta);
      positions[i * 3 + 1] = radius * Math.sin(phi) * Math.sin(theta);
      positions[i * 3 + 2] = radius * Math.cos(phi);
      
      // Tamanhos aleatórios para as estrelas
      const starSize = Math.random() * size;
      sizes[i] = starSize;
      originalSizes[i] = starSize;
    }
    
    return [positions, sizes, originalSizes];
  }, [count, depth, size]);
  
  // Efeito de cintilação (twinkle)
  useFrame(() => {
    if (starsGeometryRef.current) {
      const sizesAttribute = starsGeometryRef.current.attributes.size as THREE.BufferAttribute;
      
      for (let i = 0; i < count; i++) {
        // Fazer as estrelas cintilar alterando seus tamanhos
        const pulseSpeed = Math.random() * twinkleSpeed;
        const newSize = originalSizes[i] * (1 + 0.3 * Math.sin(Date.now() * pulseSpeed));
        sizesAttribute.array[i] = newSize;
      }
      
      sizesAttribute.needsUpdate = true;
    }
  });
  
  return (
    <points ref={starsRef}>
      <bufferGeometry ref={starsGeometryRef}>
        <bufferAttribute
          attach="attributes-position"
          count={positions.length / 3}
          array={positions}
          itemSize={3}
        />
        <bufferAttribute
          attach="attributes-size"
          count={sizes.length}
          array={sizes}
          itemSize={1}
        />
      </bufferGeometry>
      <pointsMaterial
        size={size}
        sizeAttenuation={true}
        color={color}
        transparent={true}
        opacity={0.8}
        fog={false}
        blending={THREE.AdditiveBlending}
        depthWrite={false}
        map={useMemo(() => {
          // Criar uma textura de estrela diretamente
          const canvas = document.createElement('canvas');
          canvas.width = 32;
          canvas.height = 32;
          const context = canvas.getContext('2d');
          if (context) {
            const gradient = context.createRadialGradient(16, 16, 0, 16, 16, 16);
            gradient.addColorStop(0, 'rgba(255,255,255,1)');
            gradient.addColorStop(1, 'rgba(255,255,255,0)');
            context.fillStyle = gradient;
            context.fillRect(0, 0, 32, 32);
          }
          const texture = new THREE.CanvasTexture(canvas);
          texture.needsUpdate = true;
          return texture;
        }, [])}
      >
      </pointsMaterial>
    </points>
  );
};

export default StarryBackground;
