// Sistema de colisão para as parcelas/terrenos e entre naves
import { Vector3, Mesh } from 'three';

// Interface para definir uma barreira de colisão
export interface Barrier {
  position: {
    x: number;
    y: number;
  };
  size: number;
  height: number; // Altura da barreira
}

// Classe para gerenciar colisões
export class CollisionSystem {
  private barriers: Barrier[] = [];
  private worldWidth: number = 0;
  private worldHeight: number = 0;

  constructor(worldWidth: number, worldHeight: number) {
    this.worldWidth = worldWidth;
    this.worldHeight = worldHeight;
  }

  // Adicionar uma barreira ao sistema de colisão
  addBarrier(barrier: Barrier): void {
    this.barriers.push(barrier);
  }

  // Limpar todas as barreiras
  clearBarriers(): void {
    this.barriers = [];
  }

  // Definir barreiras a partir dos slots do Mondrian
  setBarriersFromMondrian(slots: any[]): void {
    this.clearBarriers();

    slots.forEach(slot => {
      // Criar uma barreira para cada slot
      this.addBarrier({
        position: {
          x: slot.position.x,
          y: slot.position.y
        },
        size: slot.size,
        height: slot.size // A altura da barreira é exatamente igual ao tamanho da parcela
      });
    });
  }

  // Verificar se uma posição colide com alguma barreira
  checkCollision(position: Vector3): boolean {
    // Converter coordenadas do mundo para coordenadas do Mondrian
    const mondrianX = position.x + this.worldWidth / 2;
    const mondrianZ = position.z + this.worldHeight / 2;

    // Verificar se está tentando colidir com alguma parcela
    for (const barrier of this.barriers) {
      // Verificar se a nave está tentando entrar na parcela
      if (
        mondrianX >= barrier.position.x &&
        mondrianX <= barrier.position.x + barrier.size &&
        mondrianZ >= barrier.position.y &&
        mondrianZ <= barrier.position.y + barrier.size &&
        position.y <= barrier.height
      ) {
        // Está colidindo com uma parcela, há colisão
        return true;
      }
    }

    // Verificar se está fora dos limites do mapa (colisão com o "vazio")
    if (
      mondrianX < 0 ||
      mondrianX > this.worldWidth ||
      mondrianZ < 0 ||
      mondrianZ > this.worldHeight
    ) {
      return true;
    }

    // Não está colidindo com nenhuma parcela nem com o vazio
    return false;
  }

  // Aplicar um efeito físico de desvio quando a nave colide com o "vazio"
  correctPosition(currentPosition: Vector3, previousPosition: Vector3): Vector3 {
    // Se não houver colisão, retorna a posição atual
    if (!this.checkCollision(currentPosition)) {
      return currentPosition;
    }

    // Se houver colisão, encontra a parcela mais próxima
    let closestBarrier: Barrier | null = null;
    let minDistance = Infinity;

    // Converter coordenadas do mundo para coordenadas do Mondrian
    const mondrianX = currentPosition.x + this.worldWidth / 2;
    const mondrianZ = currentPosition.z + this.worldHeight / 2;

    for (const barrier of this.barriers) {
      // Calcular o centro da barreira
      const barrierCenterX = barrier.position.x + barrier.size / 2;
      const barrierCenterY = barrier.position.y + barrier.size / 2;

      // Calcular a distância ao centro da barreira
      const distance = Math.sqrt(
        Math.pow(mondrianX - barrierCenterX, 2) +
        Math.pow(mondrianZ - barrierCenterY, 2)
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestBarrier = barrier;
      }
    }

    // Se não houver barreira próxima, retorna a posição anterior com um pequeno desvio aleatório
    if (!closestBarrier) {
      // Adicionar um pequeno desvio aleatório para evitar que a nave fique presa
      const randomDeflection = new Vector3(
        (Math.random() - 0.5) * 0.2,
        0,
        (Math.random() - 0.5) * 0.2
      );
      return previousPosition.clone().add(randomDeflection);
    }

    // Calcular o vetor de movimento
    const moveVector = new Vector3().subVectors(currentPosition, previousPosition);
    const moveSpeed = moveVector.length();

    // Calcular o centro da barreira mais próxima
    const barrierCenterX = closestBarrier.position.x + closestBarrier.size / 2 - this.worldWidth / 2;
    const barrierCenterZ = closestBarrier.position.y + closestBarrier.size / 2 - this.worldHeight / 2;
    const barrierCenter = new Vector3(barrierCenterX, currentPosition.y, barrierCenterZ);

    // Calcular o vetor da posição atual para o centro da barreira
    const toCenterVector = new Vector3().subVectors(barrierCenter, currentPosition).normalize();

    // Determinar qual borda da barreira está mais próxima
    const barrierLeft = closestBarrier.position.x - this.worldWidth / 2;
    const barrierRight = barrierLeft + closestBarrier.size;
    const barrierTop = closestBarrier.position.y - this.worldHeight / 2;
    const barrierBottom = barrierTop + closestBarrier.size;

    // Calcular as distâncias para cada borda
    const distToLeft = Math.abs(currentPosition.x - barrierLeft);
    const distToRight = Math.abs(currentPosition.x - barrierRight);
    const distToTop = Math.abs(currentPosition.z - barrierTop);
    const distToBottom = Math.abs(currentPosition.z - barrierBottom);

    // Encontrar a borda mais próxima
    const minDist = Math.min(distToLeft, distToRight, distToTop, distToBottom);

    // Vetor normal da superfície de colisão
    let normal = new Vector3();

    if (minDist === distToLeft) {
      normal.set(-1, 0, 0);
    } else if (minDist === distToRight) {
      normal.set(1, 0, 0);
    } else if (minDist === distToTop) {
      normal.set(0, 0, -1);
    } else { // distToBottom
      normal.set(0, 0, 1);
    }

    // Calcular o vetor de reflexão usando a fórmula de reflexão
    // R = V - 2(V · N)N onde V é o vetor de movimento, N é o vetor normal
    const dot = moveVector.dot(normal);
    const reflectionVector = moveVector.clone().sub(
      normal.clone().multiplyScalar(2 * dot)
    );

    // Aplicar um fator de amortecimento para simular perda de energia na colisão
    // Quanto maior a velocidade, maior o amortecimento para simular resistência
    const dampingFactor = Math.max(0.5, Math.min(0.9, 1 - moveSpeed * 0.5));
    reflectionVector.multiplyScalar(dampingFactor);

    // Adicionar um componente aleatório para tornar o movimento mais natural
    const randomFactor = 0.1;
    reflectionVector.x += (Math.random() - 0.5) * randomFactor;
    reflectionVector.z += (Math.random() - 0.5) * randomFactor;

    // Calcular a nova posição aplicando o vetor de reflexão à posição anterior
    // e adicionando um pequeno deslocamento na direção da barreira mais próxima
    const newPosition = previousPosition.clone().add(reflectionVector);

    // Garantir que a altura seja mantida
    newPosition.y = currentPosition.y;

    // Verificar se a nova posição ainda está em colisão
    if (this.checkCollision(newPosition)) {
      // Se ainda estiver em colisão, tentar empurrar a nave em direção ao centro da barreira
      const pushFactor = 0.5;
      const pushPosition = newPosition.clone().add(toCenterVector.multiplyScalar(pushFactor));

      if (!this.checkCollision(pushPosition)) {
        return pushPosition;
      }

      // Se ainda estiver em colisão, retornar a posição anterior com um pequeno desvio
      // na direção oposta ao movimento original
      const oppositeDirection = moveVector.clone().negate().normalize().multiplyScalar(0.2);
      return previousPosition.clone().add(oppositeDirection);
    }

    return newPosition;
  }

  // Verificar colisão entre duas naves
  checkSpaceshipCollision(ship1Position: Vector3, ship2Position: Vector3, collisionRadius: number = 0.5): boolean {
    // Calcular a distância entre as duas naves
    const distance = ship1Position.distanceTo(ship2Position);

    // Se a distância for menor que a soma dos raios, há colisão
    return distance < collisionRadius * 2;
  }

  // Corrigir posição após colisão entre naves
  correctSpaceshipCollision(currentPosition: Vector3, otherPosition: Vector3, previousPosition: Vector3, collisionRadius: number = 0.5): Vector3 {
    // Calcular o vetor de direção da colisão (do outro para o atual)
    const collisionDirection = new Vector3().subVectors(currentPosition, otherPosition).normalize();

    // Calcular a distância de penetração
    const distance = currentPosition.distanceTo(otherPosition);
    const penetration = collisionRadius * 2 - distance;

    // Se não houver penetração, retornar a posição atual
    if (penetration <= 0) {
      return currentPosition.clone();
    }

    // Calcular o vetor de movimento
    const moveVector = new Vector3().subVectors(currentPosition, previousPosition);
    const moveSpeed = moveVector.length();

    // Calcular o vetor de reflexão usando a fórmula de reflexão
    // R = V - 2(V · N)N onde V é o vetor de movimento, N é o vetor normal (direção da colisão)
    const dot = moveVector.dot(collisionDirection);
    const reflectionVector = moveVector.clone().sub(
      collisionDirection.clone().multiplyScalar(2 * dot)
    );

    // Aplicar um fator de amortecimento para simular perda de energia na colisão
    const dampingFactor = Math.max(0.3, Math.min(0.7, 1 - moveSpeed * 0.5));
    reflectionVector.multiplyScalar(dampingFactor);

    // Adicionar um componente aleatório para tornar o movimento mais natural
    const randomFactor = 0.05;
    reflectionVector.x += (Math.random() - 0.5) * randomFactor;
    reflectionVector.z += (Math.random() - 0.5) * randomFactor;

    // Empurrar a nave para fora da colisão
    const pushVector = collisionDirection.clone().multiplyScalar(penetration + 0.1); // Adicionar uma pequena margem

    // Calcular a nova posição aplicando o vetor de reflexão e o vetor de empurrão
    const newPosition = previousPosition.clone()
      .add(reflectionVector)
      .add(pushVector);

    // Garantir que a altura seja mantida
    newPosition.y = currentPosition.y;

    return newPosition;
  }

  // Verificar colisão entre uma nave e todas as outras naves
  checkSpaceshipCollisions(shipPosition: Vector3, otherShips: Vector3[], collisionRadius: number = 0.5): { hasCollision: boolean, collidingShipIndex: number } {
    for (let i = 0; i < otherShips.length; i++) {
      if (this.checkSpaceshipCollision(shipPosition, otherShips[i], collisionRadius)) {
        return { hasCollision: true, collidingShipIndex: i };
      }
    }

    return { hasCollision: false, collidingShipIndex: -1 };
  }
}

// Exportar uma instância singleton do sistema de colisão
export const collisionSystem = new CollisionSystem(0, 0);
