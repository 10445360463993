import React, { forwardRef, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import MondrianLayout from "../utils/MondrianLayout";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Spaceship } from "./Spaceship";
import OtherPlayerSpaceship from "./OtherPlayerSpaceship";
import MultiplayerManager, { PlayerState, MultiplayerManagerRef } from "./MultiplayerManager";
import { OrbitControls, Html } from "@react-three/drei";
import { useLanguage } from '../i18n/LanguageContext';
import { collisionSystem, Barrier } from '../utils/CollisionSystem';
import ParcelBarrier from './ParcelBarrier';
import StarryBackground from './StarryBackground';
import SimpleAudioControls from './SimpleAudioControls';
import SpeakingEffect from './SpeakingEffect';

interface ThreeJSRendererProps {
    data: number[] | null;
    style?: any | {};
    onTxHover?: (tx: number) => void;
    onTxSelect?: (tx: number) => void;
    selectedBlock?: number | null;
    onCurrentParcel?: (parcelId: number | null) => void;
}

// Scene component that will contain our visualization
interface SceneProps extends Omit<ThreeJSRendererProps, 'style'> {
    speakingPlayers?: Map<string, boolean>;
}

const Scene = ({ data, onTxHover, selectedBlock, onCurrentParcel, speakingPlayers = new Map() }: SceneProps) => {
    const { t } = useLanguage();
    const mondrian = useRef<MondrianLayout | null>(null);
    const cubeInstance = useRef<THREE.InstancedMesh | null>(null);
    const sceneContainer = useRef<THREE.Group | null>(null);
    const [hovered, setHovered] = useState<number | null>(null);
    const [hoveredPosition, setHoveredPosition] = useState<THREE.Vector3 | null>(null);
    const [spaceshipPosition, setSpaceshipPosition] = useState<[number, number, number]>([0, 20, 0]);
    const [spaceshipRotation, setSpaceshipRotation] = useState<[number, number, number]>([0, Math.PI / 4, 0]);
    const [otherPlayers, setOtherPlayers] = useState<PlayerState[]>([]);
    const [barriers, setBarriers] = useState<Barrier[]>([]);
    const [worldSize, setWorldSize] = useState<{width: number, height: number}>({width: 0, height: 0});
    // Barreiras sempre ocultas conforme solicitado
    const [showBarriers] = useState<boolean>(false);
    const multiplayerRef = useRef<MultiplayerManagerRef>(null);
    const lastSelectedBlock = useRef<number | null>(null);

    const colorSettings = {
        default: new THREE.Color('#ffa500').multiplyScalar(0.8),
        hover: new THREE.Color('#ffd700').multiplyScalar(1.2),
        selected: new THREE.Color('#ff4500').multiplyScalar(0.8),
        edges: new THREE.Color('#cc5500').multiplyScalar(0.5)
    };

    // Setup raycaster for hover detection
    const { camera, raycaster, pointer } = useThree();

    useFrame(() => {
        if (!cubeInstance.current || !mondrian.current || !data) return;

        // Update raycaster
        raycaster.setFromCamera(pointer, camera);
        const intersection = raycaster.intersectObject(cubeInstance.current);

        if (intersection.length > 0) {
            const instanceId = intersection[0].instanceId;
            if (instanceId !== undefined && instanceId !== hovered) {
                // Update colors
                if (hovered !== null) {
                    cubeInstance.current.setColorAt(
                        hovered,
                        selectedBlock === hovered ? colorSettings.selected : colorSettings.default
                    );
                }
                cubeInstance.current.setColorAt(instanceId, colorSettings.hover);
                cubeInstance.current.instanceColor!.needsUpdate = true;

                // Update hover state
                setHovered(instanceId);

                // Calculate world position for label
                const slot = mondrian.current.slots[instanceId];
                const worldPos = new THREE.Vector3(
                    slot.position.x - mondrian.current.getSize().width / 2,
                    slot.size + 0.5, // Posiciona o label acima do bloco
                    slot.position.y - mondrian.current.getSize().height / 2
                );
                setHoveredPosition(worldPos);

                // Call onTxHover if provided
                if (onTxHover) onTxHover(instanceId);
            }
        } else if (hovered !== null) {
            // Reset hover state
            cubeInstance.current.setColorAt(
                hovered,
                selectedBlock === hovered ? colorSettings.selected : colorSettings.default
            );
            cubeInstance.current.instanceColor!.needsUpdate = true;
            setHovered(null);
            setHoveredPosition(null);
        }
    });

    // Update colors when selected block changes
    useEffect(() => {
        if (!data) return;

        // Initialize Mondrian layout
        mondrian.current = new MondrianLayout(data);

        // Configurar o sistema de colisão com as dimensões do mundo
        const layoutSize = mondrian.current.getSize();
        collisionSystem.clearBarriers();
        // Atualizar as dimensões do mundo no sistema de colisão
        Object.defineProperty(collisionSystem, 'worldWidth', { value: layoutSize.width });
        Object.defineProperty(collisionSystem, 'worldHeight', { value: layoutSize.height });
        // Configurar as barreiras baseadas nos slots do Mondrian
        collisionSystem.setBarriersFromMondrian(mondrian.current.slots);

        // Atualizar o estado com as barreiras e o tamanho do mundo para visualização
        setWorldSize({width: layoutSize.width, height: layoutSize.height});
        setBarriers([...mondrian.current.slots.map(slot => ({
            position: {
                x: slot.position.x,
                y: slot.position.y
            },
            size: slot.size,
            height: slot.size * 2 // A altura da barreira é o dobro do tamanho do slot
        }))]);

        // Log para debug
        console.log('Barreiras configuradas:', mondrian.current.slots.length);

        // Create geometries
        const mat = new THREE.MeshPhongMaterial({
            shininess: 50,
            specular: new THREE.Color(0xffffff).multiplyScalar(0.5),
            emissive: new THREE.Color('#ff6b00').multiplyScalar(0.1),
            emissiveIntensity: 0.2
        });

        // Adiciona material para as bordas
        const edgeMaterial = new THREE.LineBasicMaterial({
            color: colorSettings.edges,
            transparent: true,
            opacity: 0.7,
            linewidth: 1
        });

        const cubeGeometry = new THREE.BoxGeometry(0.95, 1, 0.95); // Reduz levemente o tamanho para criar espaçamento
        cubeGeometry.translate(0.5, 0.5, 0.5);

        // Cria geometria para as bordas
        const edgesGeometry = new THREE.EdgesGeometry(cubeGeometry);

        // Create instanced mesh
        cubeInstance.current = new THREE.InstancedMesh(cubeGeometry, mat, data.length);
        cubeInstance.current.frustumCulled = false;
        cubeInstance.current.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
        cubeInstance.current.castShadow = true;
        cubeInstance.current.receiveShadow = true;

        // Update instances
        const matrix = new THREE.Matrix4();
        if (sceneContainer.current) {
            for (let i = 0; i < data.length; i++) {
                const scaleValue = mondrian.current.slots[i].size;
                const pos = new THREE.Vector3(
                    mondrian.current.slots[i].position.x,
                    0,
                    mondrian.current.slots[i].position.y
                );
                const sca = new THREE.Vector3(scaleValue, scaleValue, scaleValue);
                const rot = new THREE.Quaternion();

                matrix.compose(pos, rot, sca);
                cubeInstance.current.setColorAt(i, colorSettings.default);
                cubeInstance.current.setMatrixAt(i, matrix);

                // Adiciona bordas para cada bloco
                const edges = new THREE.LineSegments(edgesGeometry, edgeMaterial);
                edges.scale.set(scaleValue, scaleValue, scaleValue);
                edges.position.copy(pos);
                sceneContainer.current.add(edges);
            }
        }

        // Add to scene container
        if (sceneContainer.current) {
            sceneContainer.current.add(cubeInstance.current);

            // Center the layout
            const layoutSize = mondrian.current.getSize();
            sceneContainer.current.position.set(-layoutSize.width / 2, 0, -layoutSize.height / 2);

            // Add ground plane
            const maxSize = Math.max(layoutSize.width, layoutSize.height);
            const groundGeometry = new THREE.PlaneGeometry(maxSize * 2, maxSize * 2);
            const groundMaterial = new THREE.ShadowMaterial({ opacity: 0.2 });
            const groundMesh = new THREE.Mesh(groundGeometry, groundMaterial);
            groundMesh.receiveShadow = true;
            groundMesh.rotation.x = -Math.PI / 2;
            sceneContainer.current.add(groundMesh);
        }

        // Update colors when selected block changes
        if (selectedBlock !== undefined && selectedBlock !== null && cubeInstance.current) {
            for (let i = 0; i < data.length; i++) {
                cubeInstance.current.setColorAt(
                    i,
                    i === selectedBlock ? colorSettings.selected : colorSettings.default
                );
            }
            cubeInstance.current.instanceColor!.needsUpdate = true;
        }

    }, [data, selectedBlock]);

    // Update spaceship position when selected block changes
    useEffect(() => {
        if (selectedBlock !== null && selectedBlock !== undefined &&
            selectedBlock !== lastSelectedBlock.current && mondrian.current) {
            const slot = mondrian.current.slots[selectedBlock];
            const worldX = slot.position.x - mondrian.current.getSize().width / 2;
            const worldY = slot.size + 2; // Posiciona a spaceship um pouco acima do bloco
            const worldZ = slot.position.y - mondrian.current.getSize().height / 2;
            setSpaceshipPosition([worldX, worldY, worldZ]);
            lastSelectedBlock.current = selectedBlock;
        }
    }, [selectedBlock]);

    // Handle spaceship position changes
    const handleSpaceshipPosition = (position: { x: number, y: number, z: number }, rotation: { x: number, y: number, z: number }) => {
        // Verificar colisão e corrigir posição se necessário
        const currentPosition = new THREE.Vector3(position.x, position.y, position.z);
        const previousPosition = new THREE.Vector3(...spaceshipPosition);

        // Verificar se há colisão com o "vazio" (fora das parcelas)
        const hasCollision = collisionSystem.checkCollision(currentPosition);

        // Se houver colisão, corrigir a posição
        if (hasCollision) {
            const correctedPosition = collisionSystem.correctPosition(currentPosition, previousPosition);
            position.x = correctedPosition.x;
            position.y = correctedPosition.y;
            position.z = correctedPosition.z;
        }

        // Atualizar posição da nave local
        setSpaceshipPosition([position.x, position.y, position.z]);
        setSpaceshipRotation([rotation.x, rotation.y, rotation.z]);

        // Enviar posição para o multiplayer manager
        multiplayerRef.current?.updatePosition(position, rotation);

        if (!mondrian.current) return;

        // Convert world coordinates to mondrian coordinates
        const mondrianX = position.x + mondrian.current.getSize().width / 2;
        const mondrianZ = position.z + mondrian.current.getSize().height / 2;

        // Find which parcel we're over
        let foundParcel: number | null = null;
        for (let i = 0; i < mondrian.current.slots.length; i++) {
            const slot = mondrian.current.slots[i];
            if (mondrianX >= slot.position.x &&
                mondrianX <= slot.position.x + slot.size &&
                mondrianZ >= slot.position.y &&
                mondrianZ <= slot.position.y + slot.size) {
                foundParcel = i;
                break;
            }
        }

        onCurrentParcel?.(foundParcel);
    };

    // Cleanup
    useEffect(() => {
        return () => {
            if (cubeInstance.current) {
                cubeInstance.current.geometry.dispose();
                (cubeInstance.current.material as THREE.Material).dispose();
            }
        };
    }, []);

    // Atualizar lista de jogadores
    const handlePlayersUpdate = (players: PlayerState[]) => {
        // Filtrar para não incluir o jogador local
        const remotePlayers = players.filter(player => {
            return player.socketId !== multiplayerRef.current?.getLocalSocketId();
        });

        // Debug para verificar quantos jogadores remotos estão sendo processados
        if (remotePlayers.length > 0) {
            console.log(`Rendering ${remotePlayers.length} remote players:`,
                remotePlayers.map(p => ({ id: p.socketId, pos: p.position })));
        }

        setOtherPlayers(remotePlayers);
    };

    // Verificar periodicamente se há jogadores remotos que não estão sendo renderizados
    useEffect(() => {
        const checkInterval = setInterval(() => {
            if (multiplayerRef.current) {
                const localSocketId = multiplayerRef.current.getLocalSocketId();
                const allPlayers = multiplayerRef.current.getAllPlayers();

                if (allPlayers && allPlayers.length > 0) {
                    const remotePlayers = allPlayers.filter((p: PlayerState) => p.socketId !== localSocketId);

                    if (remotePlayers.length > 0 && otherPlayers.length === 0) {
                        console.log('Found remote players that were not being rendered:', remotePlayers);
                        setOtherPlayers(remotePlayers);
                    }
                }
            }
        }, 1000); // Verificar a cada segundo

        return () => clearInterval(checkInterval);
    }, [otherPlayers]);

    return (
        <>
            <group ref={sceneContainer} />
            <directionalLight
                position={[50, 50, 50]}
                intensity={0.8}
                castShadow
                shadow-mapSize={[2048, 2048]}
            />
            <ambientLight intensity={0.6} />

            {/* Componente de gerenciamento multiplayer */}
            <MultiplayerManager
                ref={multiplayerRef}
                onLocalPlayerMove={handleSpaceshipPosition}
                onPlayersUpdate={handlePlayersUpdate}
            />

            {/* Nave do jogador local */}
            <Spaceship
                position={spaceshipPosition}
                rotation={spaceshipRotation}
                onPositionChange={handleSpaceshipPosition}
                otherShips={otherPlayers.map(player => ({
                    position: new THREE.Vector3(player.position.x, player.position.y, player.position.z)
                }))}
                playerId={multiplayerRef.current?.getLocalSocketId() || 'local'}
            />

            {/* Efeito de ondas sonoras para a nave local quando estiver falando */}
            {multiplayerRef.current?.getLocalSocketId() && (
                <SpeakingEffect
                    position={new THREE.Vector3(spaceshipPosition[0], spaceshipPosition[1], spaceshipPosition[2])}
                    active={speakingPlayers.get(multiplayerRef.current.getLocalSocketId() || '') || false}
                    color="#4CAF50"
                />
            )}

            {/* Naves de outros jogadores */}
            {otherPlayers.map(player => (
                <React.Fragment key={player.socketId}>
                    <OtherPlayerSpaceship
                        playerId={player.socketId}
                        position={[player.position.x, player.position.y, player.position.z]}
                        rotation={[player.rotation.x, player.rotation.y, player.rotation.z]}
                        velocity={player.velocity}
                        timestamp={player.timestamp}
                    />

                    {/* Efeito de ondas sonoras para a nave remota quando estiver falando */}
                    <SpeakingEffect
                        position={new THREE.Vector3(player.position.x, player.position.y, player.position.z)}
                        active={speakingPlayers.get(player.socketId) || false}
                        color="#2196F3"
                    />
                </React.Fragment>
            ))}

            {/* Barreiras de colisão - só mostrar se showBarriers for true */}
            {showBarriers && barriers.map((barrier, index) => (
                <ParcelBarrier
                    key={`barrier-${index}`}
                    barrier={barrier}
                    worldWidth={worldSize.width}
                    worldHeight={worldSize.height}
                />
            ))}

            {/* As barreiras estão ocultas por padrão conforme solicitado */}

            {/* Fundo estrelado */}
            <StarryBackground count={5000} depth={500} size={0.5} color="#ffffff" twinkleSpeed={0.01} />

            {/* Label para mostrar informação do bloco */}
            {hovered !== null && hoveredPosition && (
                <Html position={hoveredPosition}>
                    <div style={{
                        background: 'rgba(0,0,0,0.8)',
                        color: 'white',
                        padding: '8px',
                        borderRadius: '4px',
                        fontSize: '14px',
                        transform: 'translate(-50%, -100%)',
                        whiteSpace: 'nowrap'
                    }}>
                        {t('block')} #{hovered + 1}
                        <br />
                        {t('size')}: {mondrian.current?.slots[hovered].size.toFixed(2)}
                    </div>
                </Html>
            )}
        </>
    );
};

const ThreeJSRenderer = forwardRef(({ data, style, onTxHover, onTxSelect, selectedBlock, onCurrentParcel }: ThreeJSRendererProps) => {
    const multiplayerRef = useRef<MultiplayerManagerRef>(null);
    const [players, setPlayers] = useState<PlayerState[]>([]);
    const [speakingPlayers, setSpeakingPlayers] = useState<Map<string, boolean>>(new Map());

    // Função para atualizar a lista de jogadores
    const handlePlayersUpdate = (updatedPlayers: PlayerState[]) => {
        setPlayers(updatedPlayers);
    };

    // Lidar com mudanças no estado de fala dos jogadores
    const handleSpeakingChange = (peerId: string, isSpeaking: boolean) => {
        console.log(`Player ${peerId} is ${isSpeaking ? 'speaking' : 'silent'}`);
        setSpeakingPlayers(prev => {
            const newMap = new Map(prev);
            newMap.set(peerId, isSpeaking);
            return newMap;
        });
    };

    return (
        <div style={{ ...style, position: 'relative' }}>
            <Canvas
                shadows
                camera={{ position: [50, 50, 50], fov: 15 }}
                gl={{
                    antialias: true,
                    toneMapping: THREE.ACESFilmicToneMapping,
                    toneMappingExposure: 0.8 // Ajustando a exposição geral da cena
                }}
            >
                <Scene
                    data={data}
                    onTxHover={onTxHover}
                    onTxSelect={onTxSelect}
                    selectedBlock={selectedBlock}
                    onCurrentParcel={onCurrentParcel}
                    speakingPlayers={speakingPlayers}
                />
                <OrbitControls makeDefault />
                {/* O MultiplayerManager é renderizado dentro do Canvas, mas não renderiza nada visualmente */}
                <MultiplayerManager
                    ref={multiplayerRef}
                    onLocalPlayerMove={() => {}}
                    onPlayersUpdate={handlePlayersUpdate}
                />
            </Canvas>

            {/* O SimpleAudioControls é renderizado fora do Canvas para evitar erros de contexto 3D */}
            {multiplayerRef.current && (
                <div style={{ position: 'fixed', bottom: '20px', right: '80px', zIndex: 1000 }}>
                    <SimpleAudioControls
                        localPeerId={multiplayerRef.current.getLocalSocketId() || ''}
                        remotePeerIds={players.filter(p => p.socketId !== multiplayerRef.current?.getLocalSocketId()).map(p => p.socketId)}
                        onSpeakingChange={handleSpeakingChange}
                        localPosition={multiplayerRef.current.getLocalPosition()}
                        remotePositions={new Map(
                            players
                                .filter(p => p.socketId !== multiplayerRef.current?.getLocalSocketId())
                                .map(p => {
                                    console.log(`Mapeando posição para peer ${p.socketId}:`, p.position);
                                    return [p.socketId, p.position];
                                })
                        )}
                    />
                </div>
            )}
        </div>
    );
});

export default ThreeJSRenderer;