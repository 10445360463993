import React, { useRef, useMemo, useEffect } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { Mesh, TextureLoader, Vector3, MeshStandardMaterial, DoubleSide, Color, Euler } from 'three'
import { collisionSystem } from '../utils/CollisionSystem'

// Custom hook to create grid texture
const useGridTexture = () => {
  return useMemo(() => {
    const canvas = document.createElement('canvas')
    canvas.width = 200
    canvas.height = 200
    const ctx = canvas.getContext('2d')!

    // Set background
    const gradient = ctx.createLinearGradient(0, 0, 200, 200)
    gradient.addColorStop(0, '#00FF00') // Verde
    gradient.addColorStop(1, '#FF00FF') // Roxo
    ctx.fillStyle = gradient
    ctx.fillRect(0, 0, 200, 200)

    // Draw grid
    ctx.strokeStyle = '#000000' // Black grid lines
    ctx.lineWidth = 2

    // Create nodes and connections
    const nodes: Array<{x: number, y: number}> = []
    const cols = 8
    const rows = 10

    // Generate nodes with slight randomization
    for (let x = 0; x <= cols; x++) {
      for (let y = 0; y <= rows; y++) {
        const px = (x / cols) * 200 + (Math.random() * 20 - 10)
        const py = (y / rows) * 200 + (Math.random() * 20 - 10)
        nodes.push({ x: px, y: py })

        // Draw node
        ctx.beginPath()
        ctx.arc(px, py, 2, 0, Math.PI * 2)
        ctx.fillStyle = '#FFFFFF'
        ctx.fill()
      }
    }

    // Connect nearby nodes
    for (let i = 0; i < nodes.length; i++) {
      for (let j = i + 1; j < nodes.length; j++) {
        const dx = nodes[i].x - nodes[j].x
        const dy = nodes[i].y - nodes[j].y
        const distance = Math.sqrt(dx * dx + dy * dy)

        if (distance < 50) {
          ctx.beginPath()
          ctx.moveTo(nodes[i].x, nodes[i].y)
          ctx.lineTo(nodes[j].x, nodes[j].y)
          ctx.stroke()
        }
      }
    }

    return new TextureLoader().load(canvas.toDataURL())
  }, [])
}

interface SpaceshipProps {
  position?: [number, number, number]
  rotation?: [number, number, number]
  onPositionChange?: (position: { x: number, y: number, z: number }, rotation: { x: number, y: number, z: number }) => void
  isRemotePlayer?: boolean // Indica se é uma nave de outro jogador
  ref?: React.Ref<Mesh> // Permite passar uma ref externa
  otherShips?: { position: Vector3 }[] // Lista de outras naves para verificar colisões
  playerId?: string // ID do jogador para identificar a nave
}

export const Spaceship = React.forwardRef<Mesh, SpaceshipProps>(({
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  onPositionChange,
  isRemotePlayer = false,
  otherShips = [],
  playerId = 'local'
}, ref) => {
  // Use a ref passada ou crie uma nova
  const internalRef = useRef<Mesh>(null)
  const shipRef = (ref as React.RefObject<Mesh>) || internalRef
  const gridTexture = useGridTexture()
  const { camera } = useThree()

  // Movement state
  const moveState = useRef({
    forward: false,
    backward: false,
    left: false,
    right: false,
    up: false,
    down: false,
    speed: 0.1,
    isShiftPressed: false,
    cameraAngleX: 0,
    cameraAngleY: Math.PI / 4,
    cameraDistance: 30,
    lastMouseX: 0,
    lastMouseY: 0,
    isDragging: false,
    minZoom: 15,
    maxZoom: 50
  })

  // Setup keyboard and mouse controls - apenas para jogador local
  useEffect(() => {
    // Skip para jogadores remotos
    if (isRemotePlayer) return;
    const handleKeyDown = (e: KeyboardEvent) => {
      switch(e.key.toLowerCase()) {
        case 'w': moveState.current.forward = true; break;
        case 's': moveState.current.backward = true; break;
        case 'a': moveState.current.left = true; break;
        case 'd': moveState.current.right = true; break;
        case ' ': moveState.current.up = true; break; // Tecla espaço
        case 'control': moveState.current.down = true; break; // Tecla control
        case 'shift': moveState.current.isShiftPressed = true; break; // Tecla shift
        case 'r': // Reset position
          if (shipRef.current) {
            shipRef.current.position.set(...position);
            shipRef.current.rotation.set(0, 0, 0);
          }
          break;
      }
    }

    const handleKeyUp = (e: KeyboardEvent) => {
      switch(e.key.toLowerCase()) {
        case 'w': moveState.current.forward = false; break;
        case 's': moveState.current.backward = false; break;
        case 'a': moveState.current.left = false; break;
        case 'd': moveState.current.right = false; break;
        case ' ': moveState.current.up = false; break; // Tecla espaço
        case 'control': moveState.current.down = false; break; // Tecla control
        case 'shift': moveState.current.isShiftPressed = false; break; // Tecla shift
      }
    }

    const handleMouseDown = (e: MouseEvent) => {
      moveState.current.isDragging = true
      moveState.current.lastMouseX = e.clientX
      moveState.current.lastMouseY = e.clientY
    }

    const handleMouseUp = () => {
      moveState.current.isDragging = false
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (moveState.current.isDragging) {
        const deltaX = e.clientX - moveState.current.lastMouseX
        const deltaY = e.clientY - moveState.current.lastMouseY

        moveState.current.cameraAngleX += deltaX * 0.01
        moveState.current.cameraAngleY = Math.max(
          Math.PI / 8, // Limite inferior (não deixa a câmera ir muito baixo)
          Math.min(
            Math.PI / 2, // Limite superior (não deixa a câmera ir muito alto)
            moveState.current.cameraAngleY + deltaY * 0.01
          )
        )

        moveState.current.lastMouseX = e.clientX
        moveState.current.lastMouseY = e.clientY
      }
    }

    const handleWheel = (e: WheelEvent) => {
      e.preventDefault()

      // Ajusta a velocidade do zoom
      const zoomSpeed = 1.5
      const delta = e.deltaY * 0.01 * zoomSpeed

      // Atualiza a distância da câmera com limites
      moveState.current.cameraDistance = Math.max(
        moveState.current.minZoom,
        Math.min(
          moveState.current.maxZoom,
          moveState.current.cameraDistance + delta
        )
      )
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
    window.addEventListener('mousedown', handleMouseDown)
    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('wheel', handleWheel, { passive: false }) // Adiciona o handler de zoom

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
      window.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('mouseup', handleMouseUp)
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('wheel', handleWheel) // Remove o handler de zoom
    }
  }, [])

  // Materials
  const bodyMaterial = useMemo(() => {
    const material = new MeshStandardMaterial({
      map: gridTexture,
      metalness: 0.7,
      roughness: 0.2,
      side: DoubleSide
    })
    return material
  }, [gridTexture])

  const domeMaterial = useMemo(() =>
    new MeshStandardMaterial({
      transparent: true,
      opacity: 0.4,  // Mais transparente
      metalness: 0.1, // Menos metálico para parecer mais vidro
      roughness: 0.05, // Mais liso para refletir melhor
      color: new Color('#FFA500'),
      emissive: new Color('#FFA500'),
      emissiveIntensity: 0.3, // Mais brilho
      envMapIntensity: 1.5 // Aumenta reflexões
    }), []
  )

  const legMaterial = useMemo(() =>
    new MeshStandardMaterial({
      color: new Color('#8B4513'),
      metalness: 0.6,
      roughness: 0.3
    }), []
  )

  const propulsorMaterial = useMemo(() =>
    new MeshStandardMaterial({
      color: new Color('#000000'),
      metalness: 0.9,
      roughness: 0.1
    }), []
  )

  // Animation and movement
  useFrame(() => {
    if (shipRef.current) {
      const ship = shipRef.current

      // Skip para jogadores remotos (eles são controlados pelo OtherPlayerSpaceship)
      if (isRemotePlayer) return;

      // Set initial position only on first render or teleport
      if (!ship.userData.initialized ||
          (position[0] !== ship.userData.lastPosition?.[0] ||
           position[1] !== ship.userData.lastPosition?.[1] ||
           position[2] !== ship.userData.lastPosition?.[2])) {
        ship.position.set(...position);
        ship.userData.lastPosition = [...position];
        ship.userData.initialized = true;
        // Report initial position
        onPositionChange?.({
          x: ship.position.x,
          y: ship.position.y,
          z: ship.position.z
        }, {
          x: ship.rotation.x,
          y: ship.rotation.y,
          z: ship.rotation.z
        });
      }

      // Calculate camera position e atualiza apenas para jogador local
      if (!isRemotePlayer) {
        const cameraX = ship.position.x + Math.sin(moveState.current.cameraAngleX) * Math.cos(moveState.current.cameraAngleY) * moveState.current.cameraDistance;
        const cameraY = ship.position.y + Math.sin(moveState.current.cameraAngleY) * moveState.current.cameraDistance;
        const cameraZ = ship.position.z + Math.cos(moveState.current.cameraAngleX) * Math.cos(moveState.current.cameraAngleY) * moveState.current.cameraDistance;

        // Update camera
        camera.position.set(cameraX, cameraY, cameraZ);
        camera.lookAt(ship.position);
      }

      // Calculate current speed based on shift key state
      const currentSpeed = moveState.current.isShiftPressed ? moveState.current.speed * 2 : moveState.current.speed;

      // Manual movement - apenas para jogador local
      if (!isRemotePlayer) {
        const moveVector = new Vector3()
        if (moveState.current.forward) moveVector.z -= currentSpeed
        if (moveState.current.backward) moveVector.z += currentSpeed
        if (moveState.current.left) moveVector.x -= currentSpeed
        if (moveState.current.right) moveVector.x += currentSpeed
        if (moveState.current.up) moveVector.y += currentSpeed
        if (moveState.current.down) moveVector.y -= currentSpeed

        // Apply movement relative to camera direction
        if (moveVector.length() > 0) {
          const cameraDirection = new Vector3(0, 0, -1)
          cameraDirection.applyEuler(new Euler(0, moveState.current.cameraAngleX, 0))
          // Aplicamos a rotação apenas nos eixos X e Z, preservando o movimento vertical
          const horizontalMove = new Vector3(moveVector.x, 0, moveVector.z)
          horizontalMove.applyEuler(new Euler(0, moveState.current.cameraAngleX, 0))
          moveVector.x = horizontalMove.x
          moveVector.z = horizontalMove.z

          // Salvar a posição atual antes de aplicar o movimento
          const previousPosition = ship.position.clone();

          // Aplicar o movimento
          ship.position.add(moveVector)

          // Verificar se há colisão com parcelas ou com o "vazio" (fora do mapa)
          let hasCollision = collisionSystem.checkCollision(ship.position);
          let correctedPosition = ship.position.clone();

          // Se houver colisão com parcelas, aplicar efeito físico de desvio
          if (hasCollision) {
            // Obter a posição corrigida com efeito físico
            correctedPosition = collisionSystem.correctPosition(ship.position, previousPosition);
          }

          // Verificar colisão com outras naves
          if (otherShips.length > 0) {
            // Converter as posições das outras naves para Vector3
            const otherShipPositions = otherShips.map(ship => ship.position);

            // Verificar colisão com outras naves (usando um raio de colisão menor para que as naves precisem estar mais próximas)
            const shipCollision = collisionSystem.checkSpaceshipCollisions(ship.position, otherShipPositions, 0.4);

            // Se houver colisão com outra nave
            if (shipCollision.hasCollision) {
              // Obter a posição da nave com a qual colidiu
              const collidingShipPosition = otherShipPositions[shipCollision.collidingShipIndex];

              // Obter a posição corrigida após colisão com a nave
              correctedPosition = collisionSystem.correctSpaceshipCollision(
                ship.position,
                collidingShipPosition,
                previousPosition,
                0.4 // Usar o mesmo raio de colisão para consistência
              );

              hasCollision = true;

              // Log para debug
              console.log(`Nave ${playerId} colidiu com outra nave`);
            }
          }

          // Se houver qualquer tipo de colisão, aplicar a posição corrigida
          if (hasCollision) {
            // Aplicar a posição corrigida
            ship.position.copy(correctedPosition);

            // Adicionar um pequeno efeito de "bounce" na rotação da nave
            // para dar a sensação de impacto
            const bounceIntensity = 0.05;
            const randomBounce = (Math.random() - 0.5) * bounceIntensity;
            ship.rotation.z += randomBounce;
            ship.rotation.x += randomBounce;

            // Programar a restauração gradual da rotação
            setTimeout(() => {
              ship.rotation.z *= 0.8;
              ship.rotation.x *= 0.8;
            }, 100);
          }

          // Report position change with rotation
          onPositionChange?.({
            x: ship.position.x,
            y: ship.position.y,
            z: ship.position.z
          }, {
            x: ship.rotation.x,
            y: ship.rotation.y,
            z: ship.rotation.z
          });

          // Update ship rotation to face movement direction
          const targetRotation = Math.atan2(moveVector.x, -moveVector.z)
          const currentRotation = ship.rotation.y
          ship.rotation.y = currentRotation + (targetRotation - currentRotation) * 0.1
        }
      }
    }
  })

  return (
    <mesh ref={shipRef} position={position} rotation={rotation}>
      {/* Main body (oval with grid pattern) */}
      <mesh material={bodyMaterial} scale={[0.5, 0.17, 0.5]}>
        <sphereGeometry args={[1, 32, 32]} />
      </mesh>

      {/* Transparent orange dome */}
      <mesh material={domeMaterial} position={[0, 0.13, 0]} scale={[0.20, 0.23, 0.20]}>
        <sphereGeometry args={[1, 32, 32]} />
      </mesh>

      {/* Support legs */}
      <group position={[0, -0.13, 0]}>
        {/* Front leg */}
        <mesh material={legMaterial} position={[0, 0, 0.23]}>
          <boxGeometry args={[0.03, 0.2, 0.03]} />
        </mesh>
        {/* Back left leg */}
        <mesh material={legMaterial} position={[-0.2, 0, -0.12]}>
          <boxGeometry args={[0.03, 0.2, 0.03]} />
        </mesh>
        {/* Back right leg */}
        <mesh material={legMaterial} position={[0.2, 0, -0.12]}>
          <boxGeometry args={[0.03, 0.2, 0.03]} />
        </mesh>
      </group>

      {/* Side propulsors (closer together) */}
      <group position={[0, -0.07, -0.23]}>
        {/* Left propulsor */}
        <mesh material={propulsorMaterial} position={[-0.1, 0, 0]} rotation={[0, 0, Math.PI / 2]}>
          <cylinderGeometry args={[0.05, 0.05, 0.13, 16]} />
        </mesh>
        {/* Right propulsor */}
        <mesh material={propulsorMaterial} position={[0.1, 0, 0]} rotation={[0, 0, Math.PI / 2]}>
          <cylinderGeometry args={[0.05, 0.05, 0.13, 16]} />
        </mesh>
      </group>
    </mesh>
  )
})