import React, { useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

interface SpeakingEffectProps {
  position: THREE.Vector3;
  active: boolean;
  color?: string;
  size?: number;
  speed?: number;
  maxRadius?: number;
}

/**
 * Componente que cria um efeito visual de ondas sonoras ao redor de um jogador quando está falando
 */
const SpeakingEffect: React.FC<SpeakingEffectProps> = ({
  position,
  active,
  color = '#4CAF50',
  size = 0.5,
  speed = 0.5,
  maxRadius = 5
}) => {
  const groupRef = useRef<THREE.Group>(null);
  const ringsRef = useRef<THREE.Mesh[]>([]);
  const animationRef = useRef<{
    scales: number[];
    opacities: number[];
  }>({
    scales: [1, 1.5, 2],
    opacities: [0.8, 0.5, 0.2]
  });

  // Criar os anéis quando o componente montar
  useEffect(() => {
    if (!groupRef.current) return;

    // Limpar anéis existentes
    ringsRef.current.forEach(ring => {
      if (groupRef.current) groupRef.current.remove(ring);
      ring.geometry.dispose();
      (ring.material as THREE.Material).dispose();
    });
    ringsRef.current = [];

    // Criar novos anéis
    const ringGeometry = new THREE.RingGeometry(size, size + 0.1, 32);
    const ringMaterial = new THREE.MeshBasicMaterial({
      color: new THREE.Color(color),
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.8,
      depthWrite: false
    });

    // Criar 3 anéis com tamanhos diferentes
    for (let i = 0; i < 3; i++) {
      const ring = new THREE.Mesh(ringGeometry, ringMaterial.clone());
      ring.rotation.x = -Math.PI / 2; // Rotacionar para ficar na horizontal
      ring.visible = false;
      groupRef.current.add(ring);
      ringsRef.current.push(ring);
    }

    // Inicializar animação
    animationRef.current = {
      scales: [1, 1.5, 2],
      opacities: [0.8, 0.5, 0.2]
    };
  }, [color, size]);

  // Animar os anéis
  useFrame((_, delta) => {
    if (!groupRef.current || !active) {
      // Esconder anéis quando não estiver ativo
      ringsRef.current.forEach(ring => {
        ring.visible = false;
      });
      return;
    }

    // Atualizar posição do grupo
    groupRef.current.position.copy(position);
    
    // Mostrar e animar anéis quando ativo
    ringsRef.current.forEach((ring, index) => {
      ring.visible = true;
      
      // Atualizar escala
      animationRef.current.scales[index] += speed * delta;
      if (animationRef.current.scales[index] > maxRadius) {
        animationRef.current.scales[index] = 1;
        animationRef.current.opacities[index] = 0.8;
      }
      
      const scale = animationRef.current.scales[index];
      ring.scale.set(scale, scale, scale);
      
      // Atualizar opacidade
      const opacity = 0.8 * (1 - (scale - 1) / (maxRadius - 1));
      (ring.material as THREE.MeshBasicMaterial).opacity = Math.max(0, opacity);
    });
  });

  return (
    <group ref={groupRef} position={position}>
      {/* Os anéis são adicionados dinamicamente no useEffect */}
    </group>
  );
};

export default SpeakingEffect;
