import { useFrame } from '@react-three/fiber';
import { Mesh, Vector3, Euler, Clock } from 'three';
import { Spaceship } from './Spaceship';
import { useRef, useState, useEffect } from 'react';

interface OtherPlayerSpaceshipProps {
  position: [number, number, number];
  rotation: [number, number, number];
  playerId: string; // ID do jogador para tracking
  velocity?: { x: number, y: number, z: number }; // Velocidade recebida do servidor
  timestamp?: number; // Timestamp da última atualização
}

// Configurações de interpolação - valores ultra-otimizados para movimentos extremamente suaves
const LERP_FACTOR = 0.06; // Reduzido ainda mais para movimentos mais suaves
const THRESHOLD = 0.000005;  // Limiar mínimo para precisão extrema
const PREDICTION_SCALE = 1.2; // Reduzido para evitar sobrecorreção
const EASING_POWER = 2.5; // Ajustado para uma curva de easing mais natural

// Componente para as naves de outros jogadores
// Usa o mesmo componente Spaceship para manter a aparência idêntica
const OtherPlayerSpaceship: React.FC<OtherPlayerSpaceshipProps> = (props) => {
  const {
    position,
    rotation,
    playerId, // Usando o playerId para debug e identificação
  } = props;
  
  const shipRef = useRef<Mesh>(null);
  const clock = useRef<Clock>(new Clock());
  
  // Estado para controle de movimento
  const [lastUpdateTime, setLastUpdateTime] = useState<number>(Date.now());
  const [targetPosition, setTargetPosition] = useState<Vector3>(new Vector3(position[0], position[1], position[2]));
  const [targetRotation, setTargetRotation] = useState<Euler>(new Euler(rotation[0], rotation[1], rotation[2]));
  const [velocity, setVelocity] = useState<Vector3>(new Vector3(0, 0, 0));
  // Velocidade suavizada para movimentos mais fluidos
  const [smoothedVelocity, setSmoothedVelocity] = useState<Vector3>(new Vector3(0, 0, 0));
  const [previousPosition, setPreviousPosition] = useState<Vector3>(new Vector3(position[0], position[1], position[2]));
  const [lastPositionUpdate, setLastPositionUpdate] = useState<number>(Date.now());
  
  // Quando position ou rotation mudam, atualiza os alvos e o timestamp
  useEffect(() => {
    const now = Date.now();
    const newTarget = new Vector3(position[0], position[1], position[2]);
    
    // Log para debug (apenas para mudanças significativas)
    const distanceFromCurrent = newTarget.distanceTo(previousPosition);
    if (distanceFromCurrent > THRESHOLD * 10) {
      console.log(`Player ${playerId} received significant position update:`, position);
    }
    
    // Tempo desde a última atualização em segundos
    const timeDeltaSeconds = (now - lastUpdateTime) / 1000;
    
    // Usar a velocidade fornecida pelo servidor se disponível, caso contrário calcular
    let newVelocity;
    if (props.velocity) {
      // Usar a velocidade do servidor
      newVelocity = new Vector3(props.velocity.x, props.velocity.y, props.velocity.z);
    } else if (timeDeltaSeconds > 0) {
      // Calcular velocidade atual com base na mudança de posição
      newVelocity = new Vector3();
      newVelocity.subVectors(newTarget, previousPosition).divideScalar(Math.max(timeDeltaSeconds, 0.016));
    } else {
      newVelocity = velocity.clone(); // Manter a velocidade atual
    }
    
    // Suaviza a velocidade para evitar mudanças bruscas, com mais peso para a nova velocidade
    // Usando uma nova velocidade temporária para cálculos
    const tempSmoothedVelocity = new Vector3();
    tempSmoothedVelocity.addVectors(
      velocity.clone().multiplyScalar(0.1), // 10% da velocidade anterior (reduzido ainda mais)
      newVelocity.clone().multiplyScalar(0.9)  // 90% da nova velocidade (aumentado ainda mais)
    );
    
    // Atualiza a velocidade principal
    setVelocity(tempSmoothedVelocity.clone());
    
    // Atualiza a velocidade suavizada com uma interpolação mais gradual
    // Isso cria uma transição ainda mais suave entre estados de velocidade
    const newSmoothedVelocity = new Vector3();
    newSmoothedVelocity.addVectors(
      smoothedVelocity.clone().multiplyScalar(0.4), // 40% da velocidade suavizada anterior
      tempSmoothedVelocity.clone().multiplyScalar(0.6)  // 60% da nova velocidade calculada
    );
    setSmoothedVelocity(newSmoothedVelocity);
    
    // Se a velocidade for muito baixa mas não zero, aplicamos uma velocidade mínima
    if (tempSmoothedVelocity.length() > 0 && tempSmoothedVelocity.length() < 0.1) {
      const minVelocity = tempSmoothedVelocity.clone().normalize().multiplyScalar(0.2);
      setVelocity(minVelocity);
      setSmoothedVelocity(minVelocity.clone());
    }
    
    // Armazena a posição anterior e o timestamp para cálculos futuros
    setPreviousPosition(targetPosition.clone());
    setTargetPosition(newTarget);
    setLastUpdateTime(props.timestamp || now);
    
    // Resetar o clock para termos um delta time preciso
    clock.current.getDelta();
    
    // Atualiza a rotação alvo
    const newRotation = new Euler(rotation[0], rotation[1], rotation[2]);
    setTargetRotation(newRotation);
  }, [position, rotation, props.velocity, props.timestamp, playerId]);
  
  // Atualizar posição e rotação suavemente em cada frame
  useFrame(() => {
    if (shipRef.current) {
      const ship = shipRef.current;
      const deltaTime = clock.current.getDelta();
      const now = Date.now();
      
      // Tempo desde a última atualização de posição do servidor
      const serverUpdateAge = (now - lastUpdateTime) / 1000; // em segundos
      
      // Detectar atualizações antigas (mais de 0.15 segundo sem atualização)
      const isStale = serverUpdateAge > 0.15;
      
      // Atualizar velocidade suavizada para evitar mudanças bruscas
      // Aplicamos um filtro de suavização mais intenso para a velocidade
      // Reduzimos o fator de interpolação para uma transição ainda mais suave
      const velocityLerpFactor = Math.min(deltaTime * 1.2, 0.06); // Reduzido ainda mais para suavização extrema
      
      // Aplicamos uma interpolação com easing para a velocidade também
      const vLerp = velocityLerpFactor * velocityLerpFactor * (3 - 2 * velocityLerpFactor);
      smoothedVelocity.lerp(velocity, vLerp);
      
      // Aplicamos um filtro passa-baixa para eliminar pequenas oscilações na velocidade
      if (smoothedVelocity.length() < 0.05) {
        // Se a velocidade for muito baixa, reduzimos ainda mais para evitar tremulações
        smoothedVelocity.multiplyScalar(0.92);
      }
      
      // Posição predita com compensação de latência adaptativa
      // Ajustamos a escala de predição com base na idade da atualização
      const adaptivePredictionScale = Math.min(PREDICTION_SCALE * (1 + serverUpdateAge * 0.5), PREDICTION_SCALE * 1.5);
      const predictedPosition = targetPosition.clone().add(
        smoothedVelocity.clone().multiplyScalar(serverUpdateAge * adaptivePredictionScale)
      );
      
      // Calculamos a distância atual até o alvo
      const currentPos = ship.position.clone();
      const dist = currentPos.distanceTo(predictedPosition);
      
      // Forçar atualização se a posição não mudou por muito tempo (1.5 segundos)
      if (now - lastPositionUpdate > 1500) {
        ship.position.copy(predictedPosition);
        setLastPositionUpdate(now);
        console.log(`Forced position update for player ${playerId} due to inactivity`);
      }
      // Interpolação adaptativa - mais rápida para distâncias maiores ou dados obsoletos
      else {
        // Fator base ultra-suave, com transição mais gradual para dados obsoletos
        const baseFactor = isStale ? LERP_FACTOR * 1.1 : LERP_FACTOR; 
        
        // Fator adaptativo com curva de resposta mais suave
        // Usamos uma função de resposta não-linear para distâncias diferentes
        const distFactor = Math.min(Math.sqrt(dist) * 0.7, 1.0); // Reduzido para movimento mais suave
        const adaptiveFactor = Math.min(baseFactor * (1 + distFactor), 0.25); // Reduzido o limite máximo
        
        // Se estamos muito próximos do alvo, apenas definimos a posição final
        if (dist < THRESHOLD) {
          ship.position.copy(predictedPosition);
        } else {
          // Aplicamos interpolação ultra-suave com fator baseado em tempo
          // Reduzimos ainda mais para movimentos extremamente suaves
          const timeFactor = Math.max(
            Math.min(adaptiveFactor * deltaTime * 60, 0.3),  // limite máximo reduzido
            0.015                                        // mínimo reduzido para movimentos ainda mais suaves
          );
          
          // Aplicamos uma interpolação com curva personalizada para movimentos mais naturais
          // Usamos uma potência mais alta para uma curva de easing mais pronunciada
          // t^p / (t^p + (1-t)^p) - cria uma curva sigmoide para interpolação mais natural
          const t = timeFactor;
          const tp = Math.pow(t, EASING_POWER);
          const smoothFactor = tp / (tp + Math.pow(1 - t, EASING_POWER));
          
          ship.position.lerp(predictedPosition, smoothFactor);
          
          // Atualizar timestamp da última mudança de posição
          if (dist > THRESHOLD * 2) {
            setLastPositionUpdate(now);
          }
        }
      }
      
      // Interpolação ultra-suave para rotação
      // Reduzimos o fator para rotação mais suave, mas ainda responsiva
      const baseRotationFactor = LERP_FACTOR * 1.8 * deltaTime * 60; // Reduzido para rotação mais suave
      
      // Aplicamos uma curva de easing cúbica para rotação mais natural
      const rotationFactor = baseRotationFactor;
      const t = Math.min(rotationFactor, 0.12); // Reduzido para evitar movimentos bruscos
      const rotEasing = t * t * (3 - 2 * t); // Interpolação cúbica para rotação
      
      ship.rotation.x += (targetRotation.x - ship.rotation.x) * rotEasing;
      ship.rotation.y += (targetRotation.y - ship.rotation.y) * rotEasing;
      ship.rotation.z += (targetRotation.z - ship.rotation.z) * rotEasing;
      
      // Rotacionamos a nave na direção do movimento sempre que houver velocidade, mesmo que pequena
      if (smoothedVelocity.length() > 0.0003) { // Limiar reduzido ainda mais para captar movimentos mais sutis
        const direction = smoothedVelocity.clone().normalize();
        const targetYRotation = Math.atan2(direction.x, -direction.z);
        
        // Interpolação ultra-suave para a rotação de direção
        // Usamos uma função de easing para movimento mais natural
        const dirRotFactor = Math.min(LERP_FACTOR * 1.3 * deltaTime * 60, 0.09); // Reduzido para rotação mais suave
        const dirEasing = dirRotFactor * dirRotFactor * (3 - 2 * dirRotFactor);
        ship.rotation.y += (targetYRotation - ship.rotation.y) * dirEasing; // Suavizado para rotação mais natural
      }
      
      // Debug: mostra posição atual da nave apenas ocasionalmente
      if (Math.random() < 0.002 && dist > THRESHOLD) { // Reduzido para menos logs e apenas quando há movimento
        console.log(`Player ${playerId} at position:`, ship.position, 'distance to target:', dist.toFixed(4));
      }
    }
  });
  
  // Adicionamos o playerId como atributo de dados para debug ou referência futura
  return (
    <Spaceship 
      ref={shipRef}
      position={position} 
      rotation={rotation}
      isRemotePlayer={true} // Indica que esta é uma nave de outro jogador
      data-player-id={playerId} // Usando o playerId como atributo de dados para debugging e queries
    />
  );
};

export default OtherPlayerSpaceship;
