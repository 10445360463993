import React, { useState } from 'react';
import { useLanguage } from '../i18n/LanguageContext';

const HeaderControls: React.FC = () => {
  const { t, toggleLanguage } = useLanguage();
  const [showControls, setShowControls] = useState(false);

  return (
    <>
      {/* Buttons container */}
      <div style={{
        position: 'fixed',
        top: '20px',
        left: '20px',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }}>
        {/* Language toggle button */}
        <button
          onClick={toggleLanguage}
          style={{
            padding: '8px 16px',
            borderRadius: '8px',
            border: '1px solid rgba(138, 43, 226, 0.3)',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: '#fff',
            cursor: 'pointer',
            fontSize: '14px',
            backdropFilter: 'blur(10px)',
            transition: 'all 0.3s ease'
          }}
        >
          {t('language')}
        </button>

        {/* Controls button */}
        <button
          onClick={() => setShowControls(true)}
          style={{
            padding: '8px 16px',
            borderRadius: '8px',
            border: '1px solid rgba(138, 43, 226, 0.3)',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: '#fff',
            cursor: 'pointer',
            fontSize: '14px',
            backdropFilter: 'blur(10px)',
            transition: 'all 0.3s ease'
          }}
        >
          {t('controls')}
        </button>
      </div>

      {/* Controls modal */}
      {showControls && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1001,
          backdropFilter: 'blur(5px)'
        }}
          onClick={() => setShowControls(false)}
        >
          <div
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              padding: '20px',
              borderRadius: '12px',
              border: '1px solid rgba(138, 43, 226, 0.3)',
              color: '#fff',
              maxWidth: '400px',
              width: '90%'
            }}
            onClick={e => e.stopPropagation()}
          >
            <h3 style={{ margin: '0 0 10px 0', color: 'rgba(138, 43, 226, 0.9)' }}>
              {t('controlsTitle')}
            </h3>
            <pre style={{
              margin: 0,
              whiteSpace: 'pre-wrap',
              fontFamily: 'monospace',
              fontSize: '14px',
              lineHeight: '1.5'
            }}>
              {t('controlsText')}
            </pre>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderControls; 