import React, { useState, useEffect, useRef } from 'react';
import Peer from 'peerjs';
import { AudioProcessor, highQualityAudioConstraints, basicAudioConstraints } from '../utils/audioProcessor';
import { peerConfig } from '../utils/peerConfig';

interface SimpleAudioControlsProps {
  localPeerId: string;
  remotePeerIds: string[];
  onSpeakingChange?: (peerId: string, isSpeaking: boolean) => void;
  localPosition?: { x: number, y: number, z: number };
  remotePositions?: Map<string, { x: number, y: number, z: number }>;
}

const SimpleAudioControls: React.FC<SimpleAudioControlsProps> = ({
  localPeerId,
  remotePeerIds,
  onSpeakingChange,
  localPosition = { x: 0, y: 0, z: 0 },
  remotePositions = new Map()
}) => {
  // Estados
  const [micEnabled, setMicEnabled] = useState<boolean>(false);
  const [audioEnabled, setAudioEnabled] = useState<boolean>(true);
  const [isInitializing, setIsInitializing] = useState<boolean>(false);

  // Estados adicionais para detecção de atividade de voz
  const [isSpeaking, setIsSpeaking] = useState<boolean>(false);
  const [speakingPeers, setSpeakingPeers] = useState<Map<string, boolean>>(new Map());

  // Referências
  const peerRef = useRef<Peer | null>(null);
  const localStreamRef = useRef<MediaStream | null>(null);
  const audioElementsRef = useRef<Map<string, HTMLAudioElement>>(new Map());
  const connectionsRef = useRef<Map<string, any>>(new Map());
  const audioProcessorRef = useRef<AudioProcessor | null>(null);
  const keepAliveIntervalRef = useRef<number | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const voiceDetectionIntervalRef = useRef<number | null>(null);
  const listenerRef = useRef<AudioListener | null>(null);
  const pannerNodesRef = useRef<Map<string, PannerNode>>(new Map());
  const audioSourcesRef = useRef<Map<string, MediaElementAudioSourceNode>>(new Map());

  // Constantes
  const MAX_CONNECTIONS = 50; // Aumentado para suportar mais conexões simultâneas
  const KEEP_ALIVE_INTERVAL = 15000; // Reduzido para detectar problemas mais rapidamente

  // Constantes para áudio espacial
  const AUDIO_REF_DISTANCE = 5; // Distância de referência para atenuação
  const AUDIO_MAX_DISTANCE = 50; // Distância máxima para atenuação
  const AUDIO_ROLLOFF_FACTOR = 1.5; // Fator de atenuação com a distância
  const AUDIO_CONE_INNER_ANGLE = 360; // Ângulo interno do cone de som
  const AUDIO_CONE_OUTER_ANGLE = 360; // Ângulo externo do cone de som
  const AUDIO_CONE_OUTER_GAIN = 0.8; // Ganho fora do cone de som

  // Função para adicionar logs
  const log = (message: string) => {
    console.log(`%cAudioControls: ${message}`, 'background: #3498db; color: white; padding: 2px 4px; border-radius: 2px;');
  };

  // Função para inicializar o áudio espacial
  const initSpatialAudio = () => {
    try {
      // Criar contexto de áudio se não existir
      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
      }

      // Criar listener se não existir
      if (!listenerRef.current) {
        listenerRef.current = audioContextRef.current.listener;

        // Definir posição inicial do listener
        if (listenerRef.current.positionX) {
          // API moderna
          listenerRef.current.positionX.value = localPosition.x;
          listenerRef.current.positionY.value = localPosition.y;
          listenerRef.current.positionZ.value = localPosition.z;

          // Orientação para frente (0, 0, -1) - "nariz" apontando para frente
          listenerRef.current.forwardX.value = 0;
          listenerRef.current.forwardY.value = 0;
          listenerRef.current.forwardZ.value = -1;

          // Orientação para cima (0, 1, 0) - "cabeça" apontando para cima
          listenerRef.current.upX.value = 0;
          listenerRef.current.upY.value = 1;
          listenerRef.current.upZ.value = 0;
        } else {
          // API legada
          listenerRef.current.setPosition(localPosition.x, localPosition.y, localPosition.z);
          listenerRef.current.setOrientation(0, 0, -1, 0, 1, 0); // Forward e Up
        }
      }

      log('Áudio espacial inicializado com sucesso');
      return true;
    } catch (err) {
      log(`Erro ao inicializar áudio espacial: ${err}`);
      return false;
    }
  };

  // Função para atualizar a posição do listener
  const updateListenerPosition = () => {
    if (!listenerRef.current || !audioContextRef.current) return;

    try {
      if (listenerRef.current.positionX) {
        // API moderna
        listenerRef.current.positionX.value = localPosition.x;
        listenerRef.current.positionY.value = localPosition.y;
        listenerRef.current.positionZ.value = localPosition.z;
      } else {
        // API legada
        listenerRef.current.setPosition(localPosition.x, localPosition.y, localPosition.z);
      }
    } catch (err) {
      log(`Erro ao atualizar posição do listener: ${err}`);
    }
  };

  // Função para atualizar a posição de um panner
  const updatePannerPosition = (peerId: string) => {
    if (!pannerNodesRef.current.has(peerId)) {
      // console.log(`Panner não encontrado para peer ${peerId}. Panners disponíveis:`, Array.from(pannerNodesRef.current.keys()));
      return;
    }

    if (!remotePositions.has(peerId)) {
      // console.log(`Posição não encontrada para peer ${peerId}. Posições disponíveis:`, Array.from(remotePositions.keys()));
      return;
    }

    try {
      const panner = pannerNodesRef.current.get(peerId)!;
      const position = remotePositions.get(peerId)!;

      // console.log(`Atualizando posição do panner para peer ${peerId}:`, position);

      if (panner.positionX) {
        // API moderna
        panner.positionX.value = position.x;
        panner.positionY.value = position.y;
        panner.positionZ.value = position.z;
      } else {
        // API legada
        panner.setPosition(position.x, position.y, position.z);
      }
    } catch (err) {
      log(`Erro ao atualizar posição do panner para ${peerId}: ${err}`);
    }
  };

  // Função para configurar a detecção de atividade de voz
  const setupVoiceActivityDetection = (stream: MediaStream) => {
    // Limpar detecção anterior, se existir
    if (voiceDetectionIntervalRef.current) {
      clearInterval(voiceDetectionIntervalRef.current);
      voiceDetectionIntervalRef.current = null;
    }

    try {
      // Criar contexto de áudio se não existir
      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
      }

      // Criar analisador se não existir
      if (!analyserRef.current) {
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 256; // Valor menor para melhor performance
        analyserRef.current.smoothingTimeConstant = 0.7; // Aumentado para suavizar mais a detecção
      }

      // Conectar o stream ao analisador
      const source = audioContextRef.current.createMediaStreamSource(stream);
      source.connect(analyserRef.current);

      // Configurar o intervalo para detecção de voz
      const bufferLength = analyserRef.current.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      // Limiar de volume para considerar como fala - ajustado para ser menos sensível
      const VOICE_THRESHOLD = 20; // Reduzido para detectar vozes mais baixas

      // Contador para evitar falsos positivos/negativos
      let silenceCounter = 0;
      let speakingCounter = 0;

      // Iniciar intervalo para detecção
      voiceDetectionIntervalRef.current = window.setInterval(() => {
        if (!analyserRef.current || !micEnabled) return;

        // Obter dados de frequência
        analyserRef.current.getByteFrequencyData(dataArray);

        // Calcular volume médio
        let sum = 0;
        for (let i = 0; i < bufferLength; i++) {
          sum += dataArray[i];
        }
        const average = sum / bufferLength;

        // Verificar se está falando
        const speaking = average > VOICE_THRESHOLD;

        // Adicionar histerese para evitar oscilações rápidas
        if (speaking) {
          silenceCounter = 0;
          speakingCounter++;

          // Considerar falando após 2 detecções consecutivas (mais rápido)
          if (speakingCounter >= 2 && !isSpeaking) {
            setIsSpeaking(true);
            onSpeakingChange?.(localPeerId, true);
            log(`Detecção de voz: usuário ${localPeerId} começou a falar`);
          }
        } else {
          speakingCounter = 0;
          silenceCounter++;

          // Considerar em silêncio após 15 detecções consecutivas (mais lento para evitar cortes)
          if (silenceCounter >= 15 && isSpeaking) {
            setIsSpeaking(false);
            onSpeakingChange?.(localPeerId, false);
            log(`Detecção de voz: usuário ${localPeerId} parou de falar`);
          }
        }
      }, 100); // Verificar a cada 100ms
    } catch (err) {
      log(`Erro ao configurar detecção de voz: ${err}`);
    }
  };

  // Função para detectar atividade de voz em streams remotos
  const setupRemoteVoiceDetection = (peerId: string, audioElement: HTMLAudioElement) => {
    try {
      // Criar contexto de áudio se não existir
      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
      }

      // Criar analisador para este peer
      const analyser = audioContextRef.current.createAnalyser();
      analyser.fftSize = 256;
      analyser.smoothingTimeConstant = 0.7; // Aumentado para suavizar mais a detecção

      // Inicializar áudio espacial se ainda não foi inicializado
      if (!listenerRef.current) {
        initSpatialAudio();
      }

      // Conectar o elemento de áudio ao analisador e ao panner para áudio espacial
      const source = audioContextRef.current.createMediaElementSource(audioElement);

      // Criar nó de panorama espacial com configurações mais simples
      const panner = audioContextRef.current.createPanner();
      panner.panningModel = 'equalpower'; // Modelo mais simples e eficiente
      panner.distanceModel = 'linear'; // Modelo de atenuação mais simples
      panner.refDistance = AUDIO_REF_DISTANCE;
      panner.maxDistance = AUDIO_MAX_DISTANCE;
      panner.rolloffFactor = 1.0; // Reduzido para atenuação mais suave

      // Definir posição inicial do panner
      if (remotePositions.has(peerId)) {
        const position = remotePositions.get(peerId)!;
        log(`Configurando posição inicial de áudio para peer ${peerId}: x=${position.x}, y=${position.y}, z=${position.z}`);
        if (panner.positionX) {
          // API moderna
          panner.positionX.value = position.x;
          panner.positionY.value = position.y;
          panner.positionZ.value = position.z;
        } else {
          // API legada
          panner.setPosition(position.x, position.y, position.z);
        }
      } else {
        log(`Peer ${peerId} não tem posição definida. Usando posição padrão.`);
        // Usar posição padrão se não encontrar
        if (panner.positionX) {
          panner.positionX.value = 0;
          panner.positionY.value = 0;
          panner.positionZ.value = 0;
        } else {
          panner.setPosition(0, 0, 0);
        }
      }

      // Conectar source -> panner -> analyser -> destination
      source.connect(panner);
      panner.connect(analyser);
      analyser.connect(audioContextRef.current.destination);

      // Armazenar referências para atualização posterior
      pannerNodesRef.current.set(peerId, panner);
      audioSourcesRef.current.set(peerId, source);

      // Configurar o intervalo para detecção de voz
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      // Limiar de volume para considerar como fala
      const VOICE_THRESHOLD = 20; // Reduzido para detectar vozes mais baixas

      // Contador para evitar falsos positivos/negativos
      let silenceCounter = 0;
      let speakingCounter = 0;
      let isPeerSpeaking = false;

      // Iniciar intervalo para detecção
      const detectionInterval = window.setInterval(() => {
        if (!analyser || !audioElement.srcObject) {
          clearInterval(detectionInterval);
          return;
        }

        // Obter dados de frequência
        analyser.getByteFrequencyData(dataArray);

        // Calcular volume médio
        let sum = 0;
        for (let i = 0; i < bufferLength; i++) {
          sum += dataArray[i];
        }
        const average = sum / bufferLength;

        // Verificar se está falando
        const speaking = average > VOICE_THRESHOLD;

        // Adicionar histerese para evitar oscilações rápidas
        if (speaking) {
          silenceCounter = 0;
          speakingCounter++;

          // Considerar falando após 2 detecções consecutivas (mais rápido)
          if (speakingCounter >= 2 && !isPeerSpeaking) {
            isPeerSpeaking = true;
            setSpeakingPeers(prev => {
              const newMap = new Map(prev);
              newMap.set(peerId, true);
              return newMap;
            });
            onSpeakingChange?.(peerId, true);
            log(`Detecção de voz: peer ${peerId} começou a falar`);
          }
        } else {
          speakingCounter = 0;
          silenceCounter++;

          // Considerar em silêncio após 15 detecções consecutivas (mais lento para evitar cortes)
          if (silenceCounter >= 15 && isPeerSpeaking) {
            isPeerSpeaking = false;
            setSpeakingPeers(prev => {
              const newMap = new Map(prev);
              newMap.set(peerId, false);
              return newMap;
            });
            onSpeakingChange?.(peerId, false);
            log(`Detecção de voz: peer ${peerId} parou de falar`);
          }
        }
      }, 100); // Verificar a cada 100ms

      // Armazenar o intervalo para limpeza posterior
      return detectionInterval;
    } catch (err) {
      log(`Erro ao configurar detecção de voz para peer ${peerId}: ${err}`);
      return null;
    }
  };

  // Função para configurar os handlers de um peer
  const setupPeerHandlers = (peer: Peer) => {
    // Handler para quando o peer está aberto/conectado
    peer.on('open', (id) => {
      console.log(`PeerJS conectado com sucesso com ID: ${id}`);

      // Inicializar o microfone imediatamente após o PeerJS estar pronto
      initializeMicrophone();

      // Iniciar o mecanismo de keep-alive para manter conexões persistentes
      startKeepAlive();
    });

    // Handler para erros
    peer.on('error', (err) => {
      console.error(`Erro no PeerJS: ${err.type} - ${err.message}`);

      // Logar detalhes adicionais do erro
      console.error('Detalhes do erro PeerJS:', {
        type: err.type,
        message: err.message,
        host: import.meta.env.PROD ? import.meta.env.VITE_PEER_HOST : window.location.hostname,
        port: import.meta.env.PROD ? Number(import.meta.env.VITE_PEER_PORT) : (window.location.protocol === 'https:' ? 443 : 3000),
        path: import.meta.env.VITE_PEER_PATH || '/peerjs',
        secure: import.meta.env.PROD ? (import.meta.env.VITE_PEER_SECURE === 'true') : (window.location.protocol === 'https:'),
        env: import.meta.env.PROD ? 'production' : 'development'
      });

      // Tratamento específico para cada tipo de erro
      if (err.type === 'unavailable-id') {
        // Lógica para ID indisponível já implementada no useEffect principal
      } else if (err.type === 'network' || err.type === 'disconnected' || err.type === 'socket-error' || err.type === 'server-error') {
        console.log(`Tentando reconectar após erro de rede: ${err.type}`);
        setTimeout(() => {
          if (peerRef.current === peer && peer.disconnected) {
            console.log('Executando reconexão após erro de rede...');
            try {
              peer.reconnect();
            } catch (reconnectErr) {
              console.error('Erro ao reconectar após erro de rede:', reconnectErr);
            }
          }
        }, 2000);
      } else if (err.type === 'peer-unavailable') {
        console.log(`Peer ${err.message.split(' ')[1]} não está disponível, será chamado quando estiver online`);
      } else {
        console.error(`Erro não tratado no PeerJS: ${err.type} - ${err.message}`);
        setIsInitializing(false);
      }
    });

    // Handler para chamadas recebidas
    peer.on('call', handleIncomingCall);

    // Handler para conexão
    peer.on('connection', (conn) => {
      console.log(`Nova conexão de dados recebida de ${conn.peer}`);
    });

    // Handler para desconexão
    peer.on('disconnected', () => {
      console.log('PeerJS desconectado do servidor de sinalização');
      setTimeout(() => {
        if (peerRef.current === peer && peer.disconnected) {
          console.log('Tentando reconectar após desconexão...');
          try {
            peer.reconnect();
          } catch (err) {
            console.error('Erro ao reconectar após desconexão:', err);
          }
        }
      }, 1000);
    });
  };

  // Função para iniciar o mecanismo de keep-alive para manter conexões persistentes
  const startKeepAlive = () => {
    // Limpar intervalo existente, se houver
    stopKeepAlive();

    // Iniciar novo intervalo
    log('Iniciando mecanismo de keep-alive para manter conexões persistentes');
    keepAliveIntervalRef.current = window.setInterval(() => {
      // Verificar se o peer ainda está conectado
      if (peerRef.current && peerRef.current.disconnected) {
        log('PeerJS desconectado durante keep-alive, tentando reconectar...');
        try {
          peerRef.current.reconnect();
        } catch (err) {
          log(`Erro ao reconectar PeerJS durante keep-alive: ${err}`);
        }
      }

      // Verificar todas as conexões existentes
      if (connectionsRef.current.size > 0) {
        log(`Verificando ${connectionsRef.current.size} conexões existentes durante keep-alive...`);

        // Criar uma lista de peers para reconectar
        const peersToReconnect: string[] = [];

        // Verificar cada conexão
        connectionsRef.current.forEach((conn, peerId) => {
          try {
            // Verificar se a conexão ainda está ativa
            if (!conn.peerConnection ||
                conn.peerConnection.connectionState === 'closed' ||
                conn.peerConnection.connectionState === 'failed' ||
                conn.peerConnection.iceConnectionState === 'failed' ||
                conn.peerConnection.iceConnectionState === 'disconnected') {
              log(`Conexão com ${peerId} inativa durante keep-alive, marcando para reconexão`);
              peersToReconnect.push(peerId);
            }
          } catch (err) {
            log(`Erro ao verificar conexão com ${peerId} durante keep-alive: ${err}`);
            peersToReconnect.push(peerId);
          }
        });

        // Reconectar com os peers que precisam
        if (peersToReconnect.length > 0) {
          log(`Reconectando com ${peersToReconnect.length} peers durante keep-alive...`);
          peersToReconnect.forEach(peerId => {
            reconnectWithPeer(peerId);
          });
        }
      }

      // Verificar se precisamos chamar novos peers
      const connectedPeers = new Set(connectionsRef.current.keys());
      const peersToCall = remotePeerIds.filter(peerId => !connectedPeers.has(peerId));

      if (peersToCall.length > 0) {
        log(`Chamando ${peersToCall.length} novos peers durante keep-alive...`);
        callAllPeers();
      }
    }, KEEP_ALIVE_INTERVAL); // Verificar periodicamente
  };

  // Função para parar o mecanismo de keep-alive
  const stopKeepAlive = () => {
    if (keepAliveIntervalRef.current) {
      log('Parando mecanismo de keep-alive');
      window.clearInterval(keepAliveIntervalRef.current);
      keepAliveIntervalRef.current = null;
    }
  };

  // Configurar handlers para uma chamada
  const setupCallHandlers = (call: any) => {
    console.log(`PROCESSANDO STREAM: Configurando handlers para chamada com ${call.peer}`);

    // Processar o stream recebido
    call.on('stream', (remoteStream: MediaStream) => {
      console.log(`%cSTREAM RECEBIDO: De ${call.peer} com ${remoteStream.getAudioTracks().length} faixas de áudio`, 'background: #2ecc71; color: white; padding: 2px 4px; border-radius: 2px;');

      // Log detalhado das faixas de áudio
      remoteStream.getAudioTracks().forEach((track, index) => {
        console.log(`Faixa de áudio ${index} de ${call.peer}:`, {
          id: track.id,
          label: track.label,
          enabled: track.enabled,
          muted: track.muted,
          readyState: track.readyState
        });
      });

      // Verificar se o stream tem faixas de áudio
      if (remoteStream.getAudioTracks().length === 0) {
        console.log(`Stream de ${call.peer} não tem faixas de áudio, ignorando`);
        return;
      }

      // Remover elemento de áudio existente, se houver
      const existingAudio = audioElementsRef.current.get(call.peer);
      if (existingAudio) {
        console.log(`Removendo elemento de áudio existente para ${call.peer}`);
        try {
          existingAudio.pause();
          existingAudio.srcObject = null;
          existingAudio.remove();
        } catch (err) {
          console.error(`Erro ao remover elemento de áudio existente para ${call.peer}: ${err}`);
        }
      }

      try {
        // Criar elemento de áudio
        console.log(`%cCriando novo elemento de áudio para ${call.peer}`, 'background: #3498db; color: white; padding: 2px 4px; border-radius: 2px;');
        const audioElement = new Audio(); // Usar construtor Audio para melhor compatibilidade
        audioElement.srcObject = remoteStream;
        audioElement.autoplay = true;
        audioElement.muted = !audioEnabled; // Aplicar estado atual de mudo
        audioElement.volume = 1.0; // Garantir volume máximo
        audioElement.controls = false; // Sem controles visuais

        // Adicionar atributos para depuração e identificação
        audioElement.setAttribute('data-peer-id', call.peer);
        audioElement.setAttribute('data-created-at', new Date().toISOString());
        audioElement.setAttribute('id', `audio-${call.peer}`);

        // Configurar para melhor compatibilidade entre navegadores
        audioElement.setAttribute('playsinline', 'true'); // Importante para iOS
        audioElement.setAttribute('webkit-playsinline', 'true'); // Para Safari mais antigo
        audioElement.crossOrigin = 'anonymous';

        // Adicionar ao DOM
        document.body.appendChild(audioElement);

        // Armazenar referência
        audioElementsRef.current.set(call.peer, audioElement);

        // Iniciar reprodução
        console.log(`%cIniciando reprodução de áudio para ${call.peer}`, 'background: #e74c3c; color: white; padding: 2px 4px; border-radius: 2px;');

        // Verificar se o áudio está realmente disponível
        if (remoteStream.getAudioTracks().length === 0) {
          console.error(`Nenhuma faixa de áudio disponível no stream de ${call.peer}`);
        } else {
          // Verificar se as faixas estão habilitadas
          remoteStream.getAudioTracks().forEach(track => {
            if (!track.enabled) {
              console.warn(`Faixa de áudio de ${call.peer} está desabilitada, habilitando...`);
              track.enabled = true;
            }
          });
        }

        // Tentar reproduzir o áudio com tratamento de erro robusto
        let playAttempt = 1;
        const maxAttempts = 5; // Aumentado para mais tentativas

        const attemptPlay = () => {
          console.log(`Tentativa ${playAttempt} de reproduzir áudio para ${call.peer}`);

          // Verificar se o elemento ainda tem um srcObject válido
          if (!audioElement.srcObject) {
            console.log(`Elemento de áudio para ${call.peer} não tem srcObject, reconectando...`);
            audioElement.srcObject = remoteStream;
          }

          const playPromise = audioElement.play();

          if (playPromise !== undefined) {
            playPromise.then(() => {
              console.log(`%cReprodução de áudio iniciada com sucesso para ${call.peer}`, 'background: #2ecc71; color: white; padding: 2px 4px; border-radius: 2px;');

              // Verificar se o áudio está realmente reproduzindo
              if (audioElement.paused) {
                console.warn(`Áudio para ${call.peer} está pausado mesmo após play() bem-sucedido, tentando novamente...`);
                setTimeout(() => audioElement.play().catch(e => console.error(`Erro ao reproduzir novamente: ${e}`)), 500);
              }

              // Verificar volume
              console.log(`Volume do áudio para ${call.peer}: ${audioElement.volume}, muted: ${audioElement.muted}`);
              // Garantir que o volume está adequado
              audioElement.volume = 1.0;

              // Configurar detecção de atividade de voz para este peer
              setupRemoteVoiceDetection(call.peer, audioElement);
              console.log(`%cÁudio espacial configurado para peer ${call.peer}`, 'background: #2ecc71; color: white; padding: 2px 4px; border-radius: 2px;');

              // Adicionar um listener para verificar quando o áudio está realmente tocando
              const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
              const source = audioContext.createMediaElementSource(audioElement);
              const analyser = audioContext.createAnalyser();
              analyser.fftSize = 256;
              source.connect(analyser);
              analyser.connect(audioContext.destination);

              // Verificar periodicamente se há áudio sendo reproduzido
              const bufferLength = analyser.frequencyBinCount;
              const dataArray = new Uint8Array(bufferLength);

              const checkAudio = () => {
                analyser.getByteFrequencyData(dataArray);
                let sum = 0;
                for (let i = 0; i < bufferLength; i++) {
                  sum += dataArray[i];
                }
                const average = sum / bufferLength;
                if (average > 0) {
                  console.log(`%cÁudio detectado de ${call.peer} - Nível: ${average.toFixed(2)}`, 'background: #f39c12; color: white; padding: 2px 4px; border-radius: 2px;');
                }
              };

              // Verificar a cada segundo
              const audioCheckInterval = setInterval(checkAudio, 1000);

              // Limpar o intervalo quando o elemento for removido
              audioElement.addEventListener('ended', () => clearInterval(audioCheckInterval));

              // Adicionar listener para detectar quando o áudio parar
              audioElement.addEventListener('pause', () => {
                // Se o áudio estiver habilitado mas pausado, tentar reproduzir novamente
                if (audioEnabled && !audioElement.ended && document.visibilityState !== 'hidden') {
                  console.log(`Áudio para ${call.peer} pausado inesperadamente, tentando reproduzir novamente...`);
                  audioElement.play().catch(err => {
                    console.error(`Erro ao retomar áudio pausado para ${call.peer}: ${err}`);
                  });
                }
              });
            }).catch(err => {
              console.error(`Erro ao reproduzir áudio para ${call.peer}: ${err.message}`);

              // Tentar novamente se não excedemos o número máximo de tentativas
              if (playAttempt < maxAttempts) {
                playAttempt++;
                console.log(`Agendando nova tentativa (${playAttempt}) para ${call.peer} em 1 segundo...`);
                setTimeout(attemptPlay, 1000);
              } else {
                console.error(`Todas as ${maxAttempts} tentativas falharam para ${call.peer}. Recriando elemento...`);

                // Última tentativa: recriar o elemento de áudio
                try {
                  console.log(`Recriando elemento de áudio para peer ${call.peer}`);
                  const srcObject = audioElement.srcObject;

                  // Remover o elemento antigo
                  audioElement.pause();
                  audioElement.srcObject = null;
                  audioElement.remove();

                  // Criar um novo elemento
                  const newAudio = new Audio();
                  newAudio.srcObject = srcObject;
                  newAudio.autoplay = true;
                  newAudio.muted = !audioEnabled;
                  newAudio.setAttribute('data-peer-id', call.peer);
                  newAudio.setAttribute('data-created-at', new Date().toISOString());
                  newAudio.setAttribute('id', `audio-${call.peer}-recreated`);
                  newAudio.setAttribute('playsinline', 'true');
                  newAudio.setAttribute('webkit-playsinline', 'true');
                  newAudio.crossOrigin = 'anonymous';

                  // Adicionar ao DOM
                  document.body.appendChild(newAudio);

                  // Atualizar a referência
                  audioElementsRef.current.set(call.peer, newAudio);

                  // Tentar reproduzir
                  newAudio.play().catch(err3 => {
                    console.error(`Tentativa final falhou para ${call.peer}: ${err3.message}`);

                    // Última tentativa: usar um evento de interação do usuário para iniciar o áudio
                    console.log(`Configurando listener de clique para iniciar áudio para ${call.peer}`);
                    const startAudioOnInteraction = () => {
                      newAudio.play().catch(e => console.error(`Erro mesmo após interação: ${e}`));
                      document.removeEventListener('click', startAudioOnInteraction);
                    };
                    document.addEventListener('click', startAudioOnInteraction);
                  });

                  console.log(`Elemento de áudio recriado para peer ${call.peer}`);
                } catch (recreateErr) {
                  console.error(`Erro ao recriar elemento de áudio para peer ${call.peer}: ${recreateErr}`);
                }
              }
            });
          } else {
            console.log(`Play promise indefinido para ${call.peer}, assumindo sucesso`);
            setupRemoteVoiceDetection(call.peer, audioElement);
          }
        };

        // Iniciar a primeira tentativa
        attemptPlay();
      } catch (err) {
        log(`Erro ao criar elemento de áudio para ${call.peer}: ${err}`);
      }
    });

    // Lidar com o fechamento da chamada
    call.on('close', () => {
      console.log(`CHAMADA FECHADA: Chamada com ${call.peer} fechada`);

      // Remover da lista de conexões
      connectionsRef.current.delete(call.peer);

      // Limpar elemento de áudio
      const audioElement = audioElementsRef.current.get(call.peer);
      if (audioElement) {
        console.log(`Limpando elemento de áudio para ${call.peer} após fechamento da chamada`);
        try {
          audioElement.pause();
          audioElement.srcObject = null;
          audioElement.remove();
        } catch (err) {
          console.error(`Erro ao limpar elemento de áudio para ${call.peer}: ${err}`);
        }
        audioElementsRef.current.delete(call.peer);
      }

      // Limpar panner node
      if (pannerNodesRef.current.has(call.peer)) {
        console.log(`Limpando panner node para ${call.peer}`);
        pannerNodesRef.current.delete(call.peer);
      }

      // Limpar audio source
      if (audioSourcesRef.current.has(call.peer)) {
        console.log(`Limpando audio source para ${call.peer}`);
        audioSourcesRef.current.delete(call.peer);
      }

      // Verificar se o peer ainda está na lista de remotePeerIds
      // Se estiver, tentar reconectar após um pequeno atraso
      if (remotePeerIds.includes(call.peer)) {
        console.log(`Peer ${call.peer} ainda está na lista de remotePeerIds após fechamento da chamada, tentando reconectar em 1 segundo...`);
        setTimeout(() => {
          if (remotePeerIds.includes(call.peer) && !connectionsRef.current.has(call.peer) && peerRef.current && localStreamRef.current) {
            console.log(`Tentando reconectar com ${call.peer} após fechamento da chamada...`);
            try {
              const newCall = peerRef.current.call(call.peer, localStreamRef.current);
              if (newCall) {
                connectionsRef.current.set(call.peer, newCall);
                setupCallHandlers(newCall);
                console.log(`Reconexão com ${call.peer} iniciada após fechamento da chamada`);
              }
            } catch (err) {
              console.error(`Erro ao reconectar com ${call.peer} após fechamento da chamada: ${err}`);

              // Tentar novamente após um atraso maior
              setTimeout(() => {
                if (remotePeerIds.includes(call.peer) && !connectionsRef.current.has(call.peer) && peerRef.current && localStreamRef.current) {
                  console.log(`Segunda tentativa de reconectar com ${call.peer} após fechamento...`);
                  try {
                    const newCall = peerRef.current.call(call.peer, localStreamRef.current);
                    if (newCall) {
                      connectionsRef.current.set(call.peer, newCall);
                      setupCallHandlers(newCall);
                    }
                  } catch (err2) {
                    console.error(`Erro na segunda tentativa de reconectar: ${err2}`);
                  }
                }
              }, 3000);
            }
          }
        }, 1000); // Reduzido para 1 segundo para reconectar mais rapidamente
      }
    });

    // Lidar com erros na chamada
    call.on('error', (err: any) => {
      console.error(`ERRO NA CHAMADA: Erro com ${call.peer}: ${err.type} - ${err.message || err}`);

      // Tentar reconectar para qualquer tipo de erro
      console.log(`Tentando reconectar com ${call.peer} em 1 segundo após erro ${err.type}...`);

      // Remover a conexão atual da lista
      connectionsRef.current.delete(call.peer);

      setTimeout(() => {
        // Verificar se ainda precisamos reconectar
        if (peerRef.current && localStreamRef.current && !connectionsRef.current.has(call.peer) && remotePeerIds.includes(call.peer)) {
          console.log(`Iniciando reconexão com ${call.peer} após erro...`);
          try {
            const newCall = peerRef.current.call(call.peer, localStreamRef.current);
            if (newCall) {
              connectionsRef.current.set(call.peer, newCall);
              setupCallHandlers(newCall);
              console.log(`Reconexão com ${call.peer} iniciada após erro`);
            } else {
              console.error(`Falha ao criar nova chamada para ${call.peer}`);

              // Tentar novamente após um atraso maior
              setTimeout(() => {
                if (peerRef.current && localStreamRef.current && !connectionsRef.current.has(call.peer) && remotePeerIds.includes(call.peer)) {
                  console.log(`Nova tentativa de reconexão com ${call.peer} após falha...`);
                  try {
                    const retryCall = peerRef.current.call(call.peer, localStreamRef.current);
                    if (retryCall) {
                      connectionsRef.current.set(call.peer, retryCall);
                      setupCallHandlers(retryCall);
                    }
                  } catch (retryErr) {
                    console.error(`Erro na nova tentativa: ${retryErr}`);
                  }
                }
              }, 2000);
            }
          } catch (reconnectErr) {
            console.error(`Erro ao reconectar com ${call.peer}: ${reconnectErr}`);

            // Tentar novamente após um atraso maior
            setTimeout(() => {
              if (peerRef.current && localStreamRef.current && !connectionsRef.current.has(call.peer) && remotePeerIds.includes(call.peer)) {
                console.log(`Segunda tentativa de reconexão com ${call.peer}...`);
                try {
                  const newCall = peerRef.current.call(call.peer, localStreamRef.current);
                  if (newCall) {
                    connectionsRef.current.set(call.peer, newCall);
                    setupCallHandlers(newCall);
                    console.log(`Segunda tentativa de reconexão com ${call.peer} bem-sucedida`);
                  }
                } catch (err2) {
                  console.error(`Erro na segunda tentativa de reconectar com ${call.peer}: ${err2}`);
                }
              }
            }, 3000);
          }
        }
      }, 1000);
    });
  };

  // Inicializar microfone e PeerJS assim que o componente for montado
  useEffect(() => {
    if (!localPeerId) {
      console.error('localPeerId não fornecido, não é possível inicializar áudio');
      return;
    }

    console.log(`INICIALIZAÇÃO: Iniciando com ID: ${localPeerId}`);
    setIsInitializing(true);

    // Limpar recursos anteriores
    const cleanup = () => {
      // Parar o mecanismo de keep-alive
      stopKeepAlive();

      // Limpar instância anterior do PeerJS
      if (peerRef.current) {
        console.log('Destruindo instância anterior do PeerJS');
        try {
          peerRef.current.destroy();
        } catch (err) {
          console.error(`Erro ao destruir instância anterior do PeerJS: ${err}`);
        }
        peerRef.current = null;
      }

      // Limpar todas as conexões existentes
      connectionsRef.current.forEach((conn, peerId) => {
        console.log(`Limpando conexão existente com ${peerId}`);
        try {
          conn.close();
        } catch (err) {
          console.error(`Erro ao fechar conexão com ${peerId}: ${err}`);
        }
      });
      connectionsRef.current.clear();

      // Limpar todos os elementos de áudio existentes
      audioElementsRef.current.forEach((audio, peerId) => {
        console.log(`Limpando elemento de áudio para ${peerId}`);
        try {
          audio.pause();
          audio.srcObject = null;
          audio.remove();
        } catch (err) {
          console.error(`Erro ao limpar elemento de áudio para ${peerId}: ${err}`);
        }
      });
      audioElementsRef.current.clear();

      // Limpar stream local
      if (localStreamRef.current) {
        localStreamRef.current.getTracks().forEach(track => {
          track.stop();
        });
        localStreamRef.current = null;
      }

      // Limpar todos os panner nodes
      pannerNodesRef.current.clear();

      // Limpar todos os audio sources
      audioSourcesRef.current.clear();
    };

    // Limpar recursos anteriores
    cleanup();

    // Inicializar o PeerJS primeiro
    console.log('PASSO 1: Inicializando PeerJS...');

    // Verificar se o ID é válido
    if (localPeerId.length > 0) {
      // Usar configuração importada e adicionar configurações específicas
      const customPeerConfig = {
        debug: 3, // Nível máximo de debug para diagnóstico
        host: import.meta.env.PROD ? import.meta.env.VITE_PEER_HOST : window.location.hostname,
        port: import.meta.env.PROD ? Number(import.meta.env.VITE_PEER_PORT) : (window.location.protocol === 'https:' ? 443 : 3000),
        path: import.meta.env.VITE_PEER_PATH || '/peerjs',
        secure: import.meta.env.PROD ? (import.meta.env.VITE_PEER_SECURE === 'true') : (window.location.protocol === 'https:'),
        // Log detalhado da configuração
        pingInterval: 3000, // Intervalo de ping reduzido para detectar problemas mais rapidamente
        config: {
          iceServers: [
            // Servidores STUN públicos do Google
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'stun:stun1.l.google.com:19302' },
            { urls: 'stun:stun2.l.google.com:19302' },
            { urls: 'stun:stun3.l.google.com:19302' },
            { urls: 'stun:stun4.l.google.com:19302' },

            // Servidores TURN gratuitos
            {
              urls: 'turn:openrelay.metered.ca:80',
              username: 'openrelayproject',
              credential: 'openrelayproject'
            },
            {
              urls: 'turn:openrelay.metered.ca:443',
              username: 'openrelayproject',
              credential: 'openrelayproject'
            },
            {
              urls: 'turn:openrelay.metered.ca:443?transport=tcp',
              username: 'openrelayproject',
              credential: 'openrelayproject'
            }
          ],
          iceCandidatePoolSize: 10,
          iceTransportPolicy: 'all',
          sdpSemantics: 'unified-plan',
          rtcpMuxPolicy: 'require',
          bundlePolicy: 'max-bundle'
        }
      };

      console.log(`Criando instância PeerJS com ID: ${localPeerId}`);

      // Logar a configuração do PeerJS para depuração
      console.log('Configuração do PeerJS:', {
        host: customPeerConfig.host,
        port: customPeerConfig.port,
        path: customPeerConfig.path,
        secure: customPeerConfig.secure,
        env: import.meta.env.PROD ? 'production' : 'development'
      });

      const peer = new Peer(localPeerId, customPeerConfig);
      peerRef.current = peer;

      // Configurar todos os handlers usando a função centralizada
      setupPeerHandlers(peer);

      // Adicionar handler especial para erro de ID já em uso
      peer.on('error', (err) => {
        // Se for um erro de ID já em uso, tentar com outro ID
        if (err.type === 'unavailable-id') {
          console.log('ID já em uso, tentando com outro ID...');
          const newId = `${localPeerId}-${Math.random().toString(36).substring(2, 5)}`;
          console.log(`Tentando com novo ID: ${newId}`);

          // Destruir a instância atual
          try {
            peer.destroy();
          } catch (destroyErr) {
            console.error(`Erro ao destruir instância PeerJS: ${destroyErr}`);
          }

          // Criar uma nova instância com o novo ID
          const newPeer = new Peer(newId, customPeerConfig);
          peerRef.current = newPeer;

          // Configurar todos os handlers para a nova instância
          setupPeerHandlers(newPeer);

          // Adicionar log especial para quando a nova instância se conectar
          newPeer.on('open', () => {
            console.log(`PeerJS reconectado com novo ID: ${newId}`);
          });
        } else if (err.type === 'network' || err.type === 'disconnected' || err.type === 'socket-error' || err.type === 'server-error') {
          // Tentar reconectar em caso de erro de rede
          console.log(`Tentando reconectar após erro de rede: ${err.type}`);
          setTimeout(() => {
            if (peerRef.current === peer) {
              console.log('Executando reconexão após erro de rede...');
              try {
                peer.reconnect();
              } catch (reconnectErr) {
                console.error('Erro ao reconectar após erro de rede:', reconnectErr);

                // Se a reconexão falhar, tentar criar uma nova instância
                console.log('Tentando criar nova instância após falha na reconexão...');
                try {
                  peer.destroy();
                } catch (destroyErr) {
                  console.error('Erro ao destruir instância após falha na reconexão:', destroyErr);
                }

                // Criar nova instância com o mesmo ID
                const newPeer = new Peer(localPeerId, customPeerConfig);
                peerRef.current = newPeer;

                // Configurar handlers para a nova instância
                setupPeerHandlers(newPeer);
              }
            }
          }, 2000);
        } else if (err.type === 'peer-unavailable') {
          // Um peer não está disponível - isso é normal durante a inicialização
          console.log(`Peer ${err.message.split(' ')[1]} não está disponível, será chamado quando estiver online`);
          // Não definir isInitializing como false aqui, pois isso não é um erro fatal
        } else {
          // Para outros tipos de erro, apenas registrar e continuar
          console.log(`Erro não crítico no PeerJS: ${err.type} - ${err.message}`);
          setIsInitializing(false);
        }
      });

      peer.on('disconnected', () => {
        console.log('PeerJS desconectado, tentando reconectar...');
        if (peerRef.current === peer) {
          peer.reconnect();
        }
      });

      peer.on('connection', (conn) => {
        console.log(`Nova conexão de dados recebida de ${conn.peer}`);
        // Aceitar conexões de dados, mas não fazemos nada com elas
        // Isso é apenas para manter a compatibilidade
      });

      // Configurar handler para chamadas recebidas
      peer.on('call', handleIncomingCall);
    } else {
      console.error('ID local inválido, não é possível inicializar PeerJS');
      setIsInitializing(false);
    }

    // Limpar recursos ao desmontar
    return cleanup;
  }, [localPeerId]);

  // Função para inicializar o microfone
  const initializeMicrophone = () => {
    console.log('PASSO 2: Inicializando microfone...');

    // Usar opções básicas para maior compatibilidade entre navegadores
    const audioConstraints = {
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true,
        // Adicionar configurações para melhorar a qualidade e compatibilidade
        channelCount: 1, // Mono para melhor performance e compatibilidade
        sampleRate: 44100, // Taxa de amostragem padrão
        latency: 0.01 // Baixa latência
      },
      video: false
    };

    // Verificar se o navegador suporta getUserMedia
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error('Este navegador não suporta getUserMedia');
      alert('Seu navegador não suporta acesso ao microfone. Por favor, use um navegador moderno como Chrome, Firefox, Safari ou Edge.');
      setIsInitializing(false);
      return;
    }

    // Tentar obter acesso ao microfone com tratamento de erro robusto
    navigator.mediaDevices.getUserMedia(audioConstraints)
      .then(stream => {
        console.log(`Microfone acessado com sucesso: ${stream.getAudioTracks().length} faixas de áudio`);

        // Verificar se o stream tem faixas de áudio
        if (stream.getAudioTracks().length === 0) {
          throw new Error('Nenhuma faixa de áudio disponível no stream');
        }

        // Silenciar o stream inicialmente (mas mantê-lo ativo)
        stream.getAudioTracks().forEach(track => {
          track.enabled = false; // Silenciar inicialmente
          console.log(`Faixa de áudio inicializada: ${track.label} (silenciada)`);
        });

        // Armazenar o stream
        localStreamRef.current = stream;

        // Configurar detecção de atividade de voz
        setupVoiceActivityDetection(stream);

        // Chamar todos os peers disponíveis imediatamente
        // Isso garante que as conexões sejam estabelecidas mesmo com o microfone silenciado
        callAllPeers();

        setIsInitializing(false);
        console.log('Inicialização do microfone concluída com sucesso');
      })
      .catch(err => {
        console.error(`Erro ao acessar o microfone: ${err.name} - ${err.message}`);

        // Tratamento específico para diferentes tipos de erro
        if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
          alert('Acesso ao microfone negado. Por favor, permita o acesso ao microfone nas configurações do navegador.');
        } else if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
          alert('Nenhum microfone encontrado. Por favor, conecte um microfone e tente novamente.');
        } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
          alert('Seu microfone está sendo usado por outro aplicativo. Feche outros aplicativos e tente novamente.');
        } else {
          alert(`Erro ao acessar o microfone: ${err.message}. Tente recarregar a página.`);
        }

        setIsInitializing(false);
      });
  };

  // Função para lidar com chamadas recebidas
  const handleIncomingCall = (call: any) => {
    console.log(`CHAMADA RECEBIDA: De ${call.peer}`);

    // Verificar se já existe uma conexão com este peer
    if (connectionsRef.current.has(call.peer)) {
      console.log(`Já existe uma conexão com ${call.peer}, fechando a antiga antes de aceitar a nova`);
      try {
        const existingCall = connectionsRef.current.get(call.peer);
        if (existingCall) {
          existingCall.close();
        }
      } catch (err) {
        console.error(`Erro ao fechar conexão existente com ${call.peer}: ${err}`);
      }
    }

    // Armazenar a conexão
    connectionsRef.current.set(call.peer, call);

    // Responder à chamada
    try {
      if (localStreamRef.current) {
        // Sempre responder com o stream local, mesmo que o microfone esteja desativado
        // Isso garante que a conexão seja estabelecida corretamente e que o áudio possa
        // ser ativado/desativado posteriormente sem precisar reestabelecer a conexão
        console.log(`Respondendo à chamada de ${call.peer} com stream de áudio (microfone ${micEnabled ? 'ativado' : 'desativado'})`);
        call.answer(localStreamRef.current);
      } else {
        // Se não temos stream local, tentar obter acesso ao microfone primeiro
        console.log(`Sem stream local disponível. Tentando obter acesso ao microfone para ${call.peer}...`);

        // Usar opções básicas para maior compatibilidade
        const audioConstraints = {
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
            channelCount: 1
          },
          video: false
        };

        navigator.mediaDevices.getUserMedia(audioConstraints)
          .then(stream => {
            console.log(`Microfone acessado com sucesso para responder à chamada de ${call.peer}`);

            // Silenciar o stream inicialmente se o microfone não estiver ativado
            stream.getAudioTracks().forEach(track => {
              track.enabled = micEnabled;
            });

            // Armazenar o stream
            localStreamRef.current = stream;

            // Responder à chamada com o stream
            try {
              call.answer(stream);
              console.log(`Respondido à chamada de ${call.peer} com novo stream de áudio`);
            } catch (answerErr) {
              console.error(`Erro ao responder à chamada de ${call.peer} com novo stream: ${answerErr}`);
              try {
                call.answer(); // Tentar responder sem áudio como fallback
              } catch (fallbackErr) {
                console.error(`Erro ao responder à chamada sem áudio: ${fallbackErr}`);
              }
            }
          })
          .catch(err => {
            console.error(`Erro ao acessar o microfone para responder à chamada: ${err}`);
            // Responder sem áudio como último recurso
            try {
              call.answer();
              console.log(`Respondido à chamada de ${call.peer} sem áudio devido a erro no microfone`);
            } catch (fallbackErr) {
              console.error(`Erro ao responder à chamada sem áudio: ${fallbackErr}`);
            }
          });

        return; // Sair da função, o restante será tratado nas callbacks acima
      }
    } catch (err) {
      console.error(`Erro ao responder à chamada de ${call.peer}: ${err}`);
      // Tentar responder sem áudio como fallback
      try {
        call.answer();
        console.log(`Respondido à chamada de ${call.peer} sem áudio como fallback`);
      } catch (fallbackErr) {
        console.error(`Erro ao responder à chamada sem áudio: ${fallbackErr}`);
        return; // Sair se não conseguir responder de nenhuma forma
      }
    }

    // Configurar handlers para a chamada
    setupCallHandlers(call);
  };

  // Função para inicializar o PeerJS
  const initializePeerJS = () => {
    try {
      // Verificar se o ID do peer é válido
      let effectivePeerId = localPeerId;
      if (!localPeerId || localPeerId.length > 30) {
        log(`ID do peer inválido ou muito longo: ${localPeerId}`);
        // Gerar um ID curto e válido
        effectivePeerId = `user-${Math.random().toString(36).substring(2, 8)}`;
        log(`Usando ID alternativo: ${effectivePeerId}`);
      }

      log(`Inicializando PeerJS com ID: ${effectivePeerId}`);

      const peerConfig = {
        config: {
          iceServers: [
            // Servidores STUN públicos do Google (reduzidos para evitar sobrecarga)
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'stun:stun1.l.google.com:19302' },

            // Servidores TURN gratuitos (apenas os mais confiáveis)
            {
              urls: 'turn:openrelay.metered.ca:80',
              username: 'openrelayproject',
              credential: 'openrelayproject'
            },
            {
              urls: 'turn:openrelay.metered.ca:443',
              username: 'openrelayproject',
              credential: 'openrelayproject'
            }
          ],
          iceCandidatePoolSize: 5, // Reduzido para evitar sobrecarga
          iceTransportPolicy: 'all',
          sdpSemantics: 'unified-plan'
        },
        debug: 1, // Apenas logs importantes
        host: window.location.hostname,
        port: window.location.protocol === 'https:' ? 443 : 3000,
        path: '/peerjs',
        secure: window.location.protocol === 'https:',
        pingInterval: 5000, // 5 segundos é suficiente
        retryTimes: 3 // 3 tentativas é suficiente
      };

      log(`PASSO 3: Criando nova instância PeerJS com ID: ${effectivePeerId}`);
      const peer = new Peer(effectivePeerId, peerConfig);
      peerRef.current = peer;

      // Configurar handlers
      peer.on('open', (id) => {
        log(`%cPASSO 3.1: PeerJS conectado com sucesso com ID: ${id}`, 'background: #2ecc71; color: white; padding: 2px 4px; border-radius: 2px;');

        // Log detalhado do estado da conexão
        console.log('Estado da conexão PeerJS:', {
          id: peer.id,
          disconnected: peer.disconnected,
          destroyed: peer.destroyed,
          open: peer.open,
          connections: Object.keys(peer.connections).length
        });

        // Iniciar o mecanismo de keep-alive
        startKeepAlive();

        // Se já temos um stream local, chamar todos os peers disponíveis
        if (localStreamRef.current) {
          log('Já temos um stream local, chamando todos os peers disponíveis...');
          callAllPeers();
        } else {
          log('Ainda não temos um stream local, aguardando inicialização do microfone...');
        }
      });

      peer.on('error', (err) => {
        log(`Erro no PeerJS: ${err.type} - ${err.message}`);

        // Tratar erros específicos
        if (err.type === 'peer-unavailable') {
          // Um peer não está disponível - remover da lista de conexões
          const peerId = err.message.match(/peer\s+([\w-]+)\s+not/)?.[1];
          if (peerId && connectionsRef.current.has(peerId)) {
            log(`Removendo peer indisponível: ${peerId}`);
            connectionsRef.current.delete(peerId);
          }
        } else if (err.type === 'network' || err.type === 'disconnected') {
          // Problemas de rede - tentar reconectar
          log('Problema de rede detectado, tentando reconectar em 3 segundos...');
          setTimeout(() => {
            if (peerRef.current === peer) { // Verificar se ainda é a mesma instância
              log('Tentando reconectar após erro de rede...');
              peer.reconnect();
            }
          }, 3000);
        } else if (err.type === 'server-error') {
          // Erro no servidor - tentar reconectar
          log('Erro no servidor PeerJS, tentando reconectar em 5 segundos...');
          setTimeout(() => {
            if (peerRef.current === peer) { // Verificar se ainda é a mesma instância
              log('Tentando reconectar após erro no servidor...');
              peer.reconnect();
            }
          }, 5000);
        }
      });

      peer.on('disconnected', () => {
        log('PeerJS desconectado, tentando reconectar...');
        if (peerRef.current === peer) { // Verificar se ainda é a mesma instância
          peer.reconnect();
        }
      });

      peer.on('call', (call) => {
        console.log(`%cCHAMADA RECEBIDA: Recebendo chamada de ${call.peer}`, 'background: #e74c3c; color: white; padding: 2px 4px; border-radius: 2px;');

        // Log detalhado da chamada recebida
        console.log('Detalhes da chamada recebida:', {
          peerId: call.peer,
          connectionId: call.connectionId,
          metadata: call.metadata,
          type: call.type,
          open: call.open
        });

        // Verificar se já existe uma conexão com este peer
        if (connectionsRef.current.has(call.peer)) {
          console.log(`Já existe uma conexão com ${call.peer}, fechando a antiga antes de aceitar a nova`);
          try {
            const existingCall = connectionsRef.current.get(call.peer);
            if (existingCall) {
              existingCall.close();
            }
          } catch (err) {
            console.error(`Erro ao fechar conexão existente com ${call.peer}: ${err}`);
          }
        }

        // Armazenar a conexão
        connectionsRef.current.set(call.peer, call);

        // Responder à chamada
        try {
          if (localStreamRef.current) {
            // Sempre responder com o stream local, mesmo que o microfone esteja desativado
            console.log(`%cRespondendo à chamada de ${call.peer} com stream de áudio (microfone ${micEnabled ? 'ativado' : 'desativado'})`, 'background: #9b59b6; color: white; padding: 2px 4px; border-radius: 2px;');

            // Log detalhado do stream local
            const audioTracks = localStreamRef.current.getAudioTracks();
            console.log(`Stream local tem ${audioTracks.length} faixas de áudio:`);
            audioTracks.forEach((track, index) => {
              console.log(`Faixa ${index}:`, {
                id: track.id,
                label: track.label,
                enabled: track.enabled,
                muted: track.muted,
                readyState: track.readyState
              });
            });

            call.answer(localStreamRef.current);
          } else {
            // Se não temos stream local, responder sem áudio
            console.log(`%cSem stream local disponível. Respondendo sem áudio para ${call.peer}`, 'background: #e74c3c; color: white; padding: 2px 4px; border-radius: 2px;');
            call.answer(); // Responder sem áudio
          }
        } catch (err) {
          console.error(`Erro ao responder à chamada de ${call.peer}: ${err}`);
          // Tentar responder sem áudio como fallback
          try {
            call.answer();
            console.log(`Respondido à chamada de ${call.peer} sem áudio como fallback`);
          } catch (fallbackErr) {
            console.error(`Erro ao responder à chamada sem áudio: ${fallbackErr}`);
            return;
          }
        }

        // Configurar handlers para a chamada
        setupCallHandlers(call);
      });
      log(`PeerJS inicializado com ID: ${localPeerId}`);
      setIsInitializing(false);
    } catch (err) {
      log(`Erro ao inicializar PeerJS: ${err}`);
      setIsInitializing(false);
    }
  };

  // Limpar recursos quando o componente for desmontado
  useEffect(() => {
    return () => {
      stopKeepAlive();

      // Parar o stream local
      if (localStreamRef.current) {
        localStreamRef.current.getTracks().forEach(track => {
          try {
            track.stop();
          } catch (err) {
            // Ignorar erros ao parar
          }
        });
        localStreamRef.current = null;
      }

      // Limpar elementos de áudio
      audioElementsRef.current.forEach((audio) => {
        try {
          audio.pause();
          audio.srcObject = null;
          audio.remove();
        } catch (err) {
          // Ignorar erros ao limpar
        }
      });
      audioElementsRef.current.clear();

      // Limpar conexões
      connectionsRef.current.forEach((conn) => {
        try {
          conn.close();
        } catch (err) {
          // Ignorar erros ao fechar
        }
      });
      connectionsRef.current.clear();

      // Destruir o peer
      if (peerRef.current) {
        try {
          peerRef.current.destroy();
        } catch (err) {
          // Ignorar erros ao destruir
        }
        peerRef.current = null;
      }
    };
  }, [localPeerId]);

  // Chamar peers quando a lista de peers muda ou quando o componente é montado
  useEffect(() => {
    // Verificar se temos o peer e o stream local
    if (!peerRef.current) {
      log('PeerJS não inicializado, não é possível chamar peers');
      return;
    }

    if (!localStreamRef.current) {
      log('Stream local não disponível, não é possível chamar peers');
      return;
    }

    // Criar um array de peers que precisam ser chamados
    const peersToCall = remotePeerIds.filter(peerId => !connectionsRef.current.has(peerId));

    if (peersToCall.length === 0) {
      log('Nenhum novo peer para chamar');
      return;
    }

    log(`Iniciando chamadas para ${peersToCall.length} peers: ${peersToCall.join(', ')}`);

    // Chamar cada peer com um pequeno atraso entre as chamadas para evitar sobrecarga
    peersToCall.forEach((peerId, index) => {
      // Adicionar um pequeno atraso entre as chamadas para evitar sobrecarga
      setTimeout(() => {
        // Verificar novamente se a conexão já existe (pode ter sido criada durante o atraso)
        if (connectionsRef.current.has(peerId)) {
          log(`Já existe uma conexão com ${peerId}, ignorando chamada`);
          return;
        }

        // Verificar se o peer ainda está disponível
        if (!remotePeerIds.includes(peerId)) {
          log(`Peer ${peerId} não está mais disponível, ignorando chamada`);
          return;
        }

        // Verificar se ainda temos o stream local e o peer
        if (!localStreamRef.current || !peerRef.current) {
          log(`Stream local ou PeerJS não disponível, não é possível chamar peer ${peerId}`);
          return;
        }

        try {
          log(`Chamando peer ${peerId}...`);
          // Fazer a chamada sempre com o stream local, mesmo que o microfone esteja desativado
          // Isso garante que a conexão seja bidirecional
          const call = peerRef.current.call(peerId, localStreamRef.current);

          if (!call) {
            log(`Falha ao criar chamada para ${peerId}`);
            return;
          }

          // Armazenar a conexão
          connectionsRef.current.set(peerId, call);

          // Configurar handlers para a chamada
          setupCallHandlers(call);
          log(`Chamada iniciada para ${peerId}`);
        } catch (err) {
          log(`Erro ao chamar peer ${peerId}: ${err}`);

          // Tentar novamente após um atraso maior
          setTimeout(() => {
            if (!connectionsRef.current.has(peerId) && remotePeerIds.includes(peerId) && localStreamRef.current && peerRef.current) {
              log(`Tentando chamar peer ${peerId} novamente após erro...`);
              try {
                const call = peerRef.current.call(peerId, localStreamRef.current);
                if (call) {
                  connectionsRef.current.set(peerId, call);
                  setupCallHandlers(call);
                  log(`Segunda tentativa de chamada para ${peerId} bem-sucedida`);
                }
              } catch (retryErr) {
                log(`Erro na segunda tentativa de chamar peer ${peerId}: ${retryErr}`);
              }
            }
          }, 3000); // Tentar novamente após 3 segundos
        }
      }, index * 300); // Espaçar as chamadas em 300ms para evitar sobrecarga
    });
  }, [remotePeerIds]); // Remover micEnabled da dependência para chamar sempre que a lista de peers mudar

  // Atualizar o estado de mudo dos elementos de áudio
  useEffect(() => {
    log(`PASSO 6: Atualizando estado de áudio para ${audioElementsRef.current.size} elementos: ${audioEnabled ? 'ativado' : 'desativado'}`);

    audioElementsRef.current.forEach((audio, peerId) => {
      try {
        // Definir o estado de mudo
        audio.muted = !audioEnabled;
        log(`Áudio para peer ${peerId} ${audioEnabled ? 'ativado' : 'desativado'}`);

        // Garantir que o elemento está reproduzindo
        if (audio.paused && audio.srcObject) {
          log(`Elemento de áudio para peer ${peerId} estava pausado, tentando reproduzir`);

          // Tentar reproduzir o áudio
          const playPromise = audio.play();

          if (playPromise !== undefined) {
            playPromise.catch(err => {
              log(`Erro ao reproduzir áudio para peer ${peerId}: ${err}`);

              // Tentar novamente com interação do usuário
              setTimeout(() => {
                if (audio.paused && audio.srcObject) {
                  log(`Segunda tentativa de reproduzir áudio para peer ${peerId}`);
                  audio.play().catch(err2 => {
                    log(`Segunda tentativa falhou para peer ${peerId}: ${err2}`);

                    // Última tentativa: recriar o elemento de áudio
                    try {
                      log(`Recriando elemento de áudio para peer ${peerId}`);
                      const srcObject = audio.srcObject;

                      // Remover o elemento antigo
                      audio.pause();
                      audio.srcObject = null;
                      audio.remove();

                      // Criar um novo elemento
                      const newAudio = document.createElement('audio');
                      newAudio.srcObject = srcObject;
                      newAudio.autoplay = true;
                      newAudio.muted = !audioEnabled;
                      newAudio.setAttribute('data-peer-id', peerId);
                      newAudio.setAttribute('data-created-at', new Date().toISOString());

                      // Adicionar ao DOM
                      document.body.appendChild(newAudio);

                      // Atualizar a referência
                      audioElementsRef.current.set(peerId, newAudio);

                      log(`Elemento de áudio recriado para peer ${peerId}`);
                    } catch (recreateErr) {
                      log(`Erro ao recriar elemento de áudio para peer ${peerId}: ${recreateErr}`);
                    }
                  });
                }
              }, 1000);
            });
          }
        }
      } catch (err) {
        log(`Erro ao atualizar estado de áudio para peer ${peerId}: ${err}`);
      }
    });

    // Se estamos ativando o áudio, verificar se temos conexões com todos os peers disponíveis
    if (audioEnabled) {
      log('PASSO 6.1: Verificando conexões com todos os peers disponíveis...');
      callAllPeers();
    }
  }, [audioEnabled]);

  // Ouvir evento de saída de jogador para limpar conexões
  useEffect(() => {
    const handlePlayerLeft = (event: CustomEvent) => {
      const { socketId, peerId } = event.detail;
      log(`PASSO 7: Recebido evento player:left para socketId=${socketId}, peerId=${peerId}`);

      // Usar o peerId ou o socketId como identificador (para compatibilidade)
      const peerIdentifier = peerId || socketId;

      // Limpar conexão PeerJS
      if (connectionsRef.current.has(peerIdentifier)) {
        log(`Limpando conexão PeerJS para peer ${peerIdentifier}`);
        const connection = connectionsRef.current.get(peerIdentifier);

        try {
          if (connection) {
            connection.close();
          }
        } catch (err) {
          log(`Erro ao fechar conexão com ${peerIdentifier}: ${err}`);
        }

        connectionsRef.current.delete(peerIdentifier);
      }

      // Limpar elemento de áudio
      if (audioElementsRef.current.has(peerIdentifier)) {
        log(`Limpando elemento de áudio para peer ${peerIdentifier}`);
        const audioElement = audioElementsRef.current.get(peerIdentifier);

        try {
          if (audioElement) {
            audioElement.pause();
            audioElement.srcObject = null;
            audioElement.remove();
          }
        } catch (err) {
          log(`Erro ao limpar elemento de áudio para ${peerIdentifier}: ${err}`);
        }

        audioElementsRef.current.delete(peerIdentifier);
      }

      // Limpar panner node
      if (pannerNodesRef.current.has(peerIdentifier)) {
        log(`Limpando panner node para peer ${peerIdentifier}`);
        pannerNodesRef.current.delete(peerIdentifier);
      }

      // Limpar audio source
      if (audioSourcesRef.current.has(peerIdentifier)) {
        log(`Limpando audio source para peer ${peerIdentifier}`);
        audioSourcesRef.current.delete(peerIdentifier);
      }

      // Atualizar estado de speaking peers
      setSpeakingPeers(prev => {
        const newMap = new Map(prev);
        newMap.delete(peerIdentifier);
        return newMap;
      });

      // Verificar se o peer ainda está na lista de remotePeerIds
      // Se estiver, pode ser que o evento tenha sido disparado incorretamente
      if (remotePeerIds.includes(peerIdentifier)) {
        log(`Aviso: Peer ${peerIdentifier} ainda está na lista de remotePeerIds após evento player:left`);
      }
    };

    // Adicionar listener para o evento personalizado
    window.addEventListener('player:left', handlePlayerLeft as EventListener);

    // Limpar listener ao desmontar
    return () => {
      window.removeEventListener('player:left', handlePlayerLeft as EventListener);
    };
  }, [remotePeerIds]); // Adicionar remotePeerIds como dependência para ter acesso à lista atualizada

  // Monitorar conexões e reconectar automaticamente se necessário
  useEffect(() => {
    log(`PASSO 8: Iniciando monitoramento de conexões para ${remotePeerIds.length} peers...`);

    // Verificar conexões a cada 10 segundos
    const intervalId = setInterval(() => {
      // Verificar se temos conexões com todos os peers disponíveis
      const connectedPeers = Array.from(connectionsRef.current.keys());
      const missingPeers = remotePeerIds.filter(peerId => !connectedPeers.includes(peerId));

      if (missingPeers.length > 0) {
        log(`PASSO 8.1: Detectados ${missingPeers.length} peers sem conexão: ${missingPeers.join(', ')}`);
        callAllPeers(); // Tentar reconectar
      } else if (connectedPeers.length > 0) {
        log(`Todas as conexões estão ativas (${connectedPeers.length} peers conectados)`);
      }

      // Verificar elementos de áudio
      const audioElements = Array.from(audioElementsRef.current.keys());
      const peersWithoutAudio = remotePeerIds.filter(peerId => !audioElements.includes(peerId));

      if (peersWithoutAudio.length > 0) {
        log(`PASSO 8.2: Detectados ${peersWithoutAudio.length} peers sem elemento de áudio: ${peersWithoutAudio.join(', ')}`);
        // Os elementos de áudio são criados automaticamente quando recebemos um stream
      }

      // Verificar elementos de áudio pausados
      let pausedCount = 0;
      audioElementsRef.current.forEach((audio, peerId) => {
        if (audio.paused && audio.srcObject) {
          pausedCount++;
          if (audioEnabled) {
            log(`PASSO 8.3: Elemento de áudio para peer ${peerId} está pausado, tentando reproduzir`);
            audio.play().catch(err => {
              log(`Erro ao reproduzir áudio pausado para peer ${peerId}: ${err}`);
            });
          }
        }
      });

      if (pausedCount > 0) {
        log(`Detectados ${pausedCount} elementos de áudio pausados`);
      }
    }, 10000); // Verificar a cada 10 segundos

    return () => {
      clearInterval(intervalId);
    };
  }, [remotePeerIds, audioEnabled]);

  // Atualizar posições do áudio espacial quando as posições mudarem
  useEffect(() => {
    // Atualizar posição do listener
    updateListenerPosition();

    // Atualizar posição de cada panner
    remotePositions.forEach((position, peerId) => {
      updatePannerPosition(peerId);
      log(`Atualizando posição de áudio para peer ${peerId}`);
    });
  }, [localPosition, remotePositions]);

  // Função aprimorada para chamar todos os peers disponíveis
  const callAllPeers = () => {
    if (!peerRef.current) {
      log('PeerJS não inicializado, não é possível chamar peers');
      return;
    }

    // Se não temos um stream local, tentar obter acesso ao microfone primeiro
    if (!localStreamRef.current) {
      log('Stream local não disponível, tentando obter acesso ao microfone...');

      // Usar opções básicas para maior compatibilidade
      const audioConstraints = {
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          channelCount: 1
        },
        video: false
      };

      navigator.mediaDevices.getUserMedia(audioConstraints)
        .then(stream => {
          log('Microfone acessado com sucesso para chamar peers');

          // Silenciar o stream inicialmente se o microfone não estiver ativado
          stream.getAudioTracks().forEach(track => {
            track.enabled = micEnabled;
            log(`Faixa de áudio ${micEnabled ? 'ativada' : 'silenciada'}: ${track.label}`);
          });

          // Armazenar o stream
          localStreamRef.current = stream;

          // Agora que temos um stream, chamar todos os peers
          performCallToPeers();
        })
        .catch(err => {
          log(`Erro ao acessar o microfone para chamar peers: ${err}`);
          // Tentar chamar sem áudio como fallback
          performCallToPeers(true);
        });
      return;
    }

    // Se temos um stream local, chamar todos os peers
    performCallToPeers();
  };

  // Função auxiliar para realizar as chamadas para os peers
  const performCallToPeers = (withoutAudio = false) => {
    if (!peerRef.current) {
      log('PeerJS não inicializado, não é possível chamar peers');
      return;
    }

    log(`%cPASSO 2: Chamando todos os ${remotePeerIds.length} peers disponíveis...`, 'background: #3498db; color: white; padding: 2px 4px; border-radius: 2px;');

    // Log detalhado do estado do PeerJS
    console.log('Estado atual do PeerJS:', {
      id: peerRef.current.id,
      disconnected: peerRef.current.disconnected,
      destroyed: peerRef.current.destroyed,
      open: peerRef.current.open,
      connections: Object.keys(peerRef.current.connections).length
    });

    // Verificar todas as conexões existentes para garantir que estão ativas
    const activeConnections = new Set<string>();

    connectionsRef.current.forEach((conn, peerId) => {
      // Verificar se a conexão está ativa
      if (conn && conn.peerConnection &&
          conn.peerConnection.connectionState !== 'closed' &&
          conn.peerConnection.connectionState !== 'failed' &&
          conn.peerConnection.iceConnectionState !== 'failed' &&
          conn.peerConnection.iceConnectionState !== 'disconnected') {
        activeConnections.add(peerId);
      } else {
        // Remover conexões inativas
        log(`Removendo conexão inativa com ${peerId}`);
        try {
          conn.close();
        } catch (err) {
          // Ignorar erros ao fechar conexões já fechadas
        }
        connectionsRef.current.delete(peerId);
      }
    });

    // Criar um array de peers que precisam ser chamados (todos os que não têm conexões ativas)
    const peersToCall = remotePeerIds.filter(peerId => !activeConnections.has(peerId));

    if (peersToCall.length === 0) {
      log('Todos os peers já estão conectados');
      return;
    }

    log(`Iniciando chamadas para ${peersToCall.length} peers: ${peersToCall.join(', ')}`);

    // Chamar cada peer com um pequeno atraso entre as chamadas para evitar sobrecarga
    peersToCall.forEach((peerId, index) => {
      setTimeout(() => {
        // Verificar novamente se a conexão já existe
        if (connectionsRef.current.has(peerId)) {
          log(`Já existe uma conexão com ${peerId}, verificando se está ativa...`);

          const conn = connectionsRef.current.get(peerId);
          if (conn && conn.peerConnection &&
              conn.peerConnection.connectionState !== 'closed' &&
              conn.peerConnection.connectionState !== 'failed' &&
              conn.peerConnection.iceConnectionState !== 'failed' &&
              conn.peerConnection.iceConnectionState !== 'disconnected') {
            log(`Conexão com ${peerId} está ativa, ignorando chamada`);
            return;
          } else {
            log(`Conexão com ${peerId} não está ativa, fechando e recriando...`);
            try {
              conn.close();
            } catch (err) {
              // Ignorar erros ao fechar conexões já fechadas
            }
            connectionsRef.current.delete(peerId);
          }
        }

        // Verificar se o peer ainda está disponível
        if (!remotePeerIds.includes(peerId)) {
          log(`Peer ${peerId} não está mais disponível, ignorando chamada`);
          return;
        }

        try {
          log(`%cChamando peer ${peerId}...`, 'background: #3498db; color: white; padding: 2px 4px; border-radius: 2px;');

          // Log detalhado do stream local antes de fazer a chamada
          if (!withoutAudio && localStreamRef.current) {
            const audioTracks = localStreamRef.current.getAudioTracks();
            console.log(`Stream local para chamada tem ${audioTracks.length} faixas de áudio:`);
            audioTracks.forEach((track, index) => {
              console.log(`Faixa ${index} para chamada:`, {
                id: track.id,
                label: track.label,
                enabled: track.enabled,
                muted: track.muted,
                readyState: track.readyState
              });
            });
          }

          // Fazer a chamada com ou sem stream de áudio
          let call;
          if (!withoutAudio && localStreamRef.current) {
            // Fazer a chamada com o stream local
            call = peerRef.current.call(peerId, localStreamRef.current);
            log(`%cChamada iniciada para ${peerId} com stream de áudio`, 'background: #2ecc71; color: white; padding: 2px 4px; border-radius: 2px;');
          } else {
            // Criar um stream vazio como fallback
            const emptyStream = new MediaStream();
            call = peerRef.current.call(peerId, emptyStream);
            log(`%cChamada iniciada para ${peerId} sem stream de áudio (fallback)`, 'background: #e74c3c; color: white; padding: 2px 4px; border-radius: 2px;');
          }

          // Log detalhado da chamada criada
          if (call) {
            console.log('Detalhes da chamada criada:', {
              peerId: call.peer,
              connectionId: call.connectionId,
              metadata: call.metadata,
              type: call.type,
              open: call.open
            });
          }

          if (!call) {
            log(`Falha ao criar chamada para ${peerId}`);
            return;
          }

          // Armazenar a conexão
          connectionsRef.current.set(peerId, call);

          // Configurar handlers para a chamada
          setupCallHandlers(call);
        } catch (err) {
          log(`Erro ao chamar peer ${peerId}: ${err}`);

          // Tentar novamente após um atraso maior
          setTimeout(() => {
            if (!connectionsRef.current.has(peerId) && remotePeerIds.includes(peerId)) {
              log(`Tentando chamar peer ${peerId} novamente após erro...`);
              try {
                let retryCall;
                if (!withoutAudio && localStreamRef.current) {
                  retryCall = peerRef.current.call(peerId, localStreamRef.current);
                } else {
                  const emptyStream = new MediaStream();
                  retryCall = peerRef.current.call(peerId, emptyStream);
                }

                if (retryCall) {
                  connectionsRef.current.set(peerId, retryCall);
                  setupCallHandlers(retryCall);
                  log(`Segunda tentativa de chamada para ${peerId} bem-sucedida`);
                }
              } catch (retryErr) {
                log(`Erro na segunda tentativa de chamar peer ${peerId}: ${retryErr}`);

                // Última tentativa com um atraso ainda maior
                setTimeout(() => {
                  if (!connectionsRef.current.has(peerId) && remotePeerIds.includes(peerId) && peerRef.current) {
                    log(`Terceira tentativa de chamar peer ${peerId}...`);
                    try {
                      // Tentar com um stream vazio como último recurso
                      const emptyStream = new MediaStream();
                      const lastCall = peerRef.current.call(peerId, emptyStream);
                      if (lastCall) {
                        connectionsRef.current.set(peerId, lastCall);
                        setupCallHandlers(lastCall);
                      }
                    } catch (finalErr) {
                      log(`Erro na terceira tentativa de chamar peer ${peerId}: ${finalErr}`);
                    }
                  }
                }, 5000);
              }
            }
          }, 2000); // Tentar novamente após 2 segundos
        }
      }, index * 200); // Espaçar as chamadas em 200ms para evitar sobrecarga
    });
  };

  // Função para reconectar com um peer específico
  const reconnectWithPeer = (peerId: string) => {
    if (!peerRef.current || !remotePeerIds.includes(peerId)) {
      log(`Não é possível reconectar com ${peerId}: PeerJS não inicializado ou peer não está na lista`);
      return;
    }

    log(`Tentando reconectar com peer ${peerId}...`);

    // Fechar a conexão existente, se houver
    const existingCall = connectionsRef.current.get(peerId);
    if (existingCall) {
      try {
        existingCall.close();
      } catch (err) {
        log(`Erro ao fechar conexão existente com ${peerId}: ${err}`);
      }
      connectionsRef.current.delete(peerId);
    }

    // Remover elemento de áudio existente, se houver
    const existingAudio = audioElementsRef.current.get(peerId);
    if (existingAudio) {
      try {
        existingAudio.pause();
        existingAudio.srcObject = null;
        existingAudio.remove();
      } catch (err) {
        log(`Erro ao remover elemento de áudio existente para ${peerId}: ${err}`);
      }
      audioElementsRef.current.delete(peerId);
    }

    // Limpar panner node
    if (pannerNodesRef.current.has(peerId)) {
      pannerNodesRef.current.delete(peerId);
    }

    // Limpar audio source
    if (audioSourcesRef.current.has(peerId)) {
      audioSourcesRef.current.delete(peerId);
    }

    // Tentar estabelecer uma nova conexão
    try {
      if (localStreamRef.current) {
        log(`Chamando peer ${peerId} com stream de áudio...`);
        const call = peerRef.current.call(peerId, localStreamRef.current);
        if (call) {
          connectionsRef.current.set(peerId, call);
          setupCallHandlers(call);
          log(`Reconexão com ${peerId} iniciada com sucesso`);
        } else {
          log(`Falha ao criar nova chamada para ${peerId}`);
        }
      } else {
        log(`Sem stream local disponível para reconectar com ${peerId}, tentando obter acesso ao microfone...`);

        // Usar opções básicas para maior compatibilidade
        const audioConstraints = {
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
            channelCount: 1
          },
          video: false
        };

        navigator.mediaDevices.getUserMedia(audioConstraints)
          .then(stream => {
            log(`Microfone acessado com sucesso para reconectar com ${peerId}`);

            // Silenciar o stream inicialmente se o microfone não estiver ativado
            stream.getAudioTracks().forEach(track => {
              track.enabled = micEnabled;
            });

            // Armazenar o stream
            localStreamRef.current = stream;

            // Tentar estabelecer a conexão novamente
            if (peerRef.current) {
              const call = peerRef.current.call(peerId, stream);
              if (call) {
                connectionsRef.current.set(peerId, call);
                setupCallHandlers(call);
                log(`Reconexão com ${peerId} iniciada com novo stream de áudio`);
              } else {
                log(`Falha ao criar nova chamada para ${peerId} mesmo com novo stream`);
              }
            }
          })
          .catch(err => {
            log(`Erro ao acessar o microfone para reconectar com ${peerId}: ${err}`);

            // Tentar com um stream vazio como último recurso
            if (peerRef.current) {
              const emptyStream = new MediaStream();
              const call = peerRef.current.call(peerId, emptyStream);
              if (call) {
                connectionsRef.current.set(peerId, call);
                setupCallHandlers(call);
                log(`Reconexão com ${peerId} iniciada com stream vazio como fallback`);
              }
            }
          });
      }
    } catch (err) {
      log(`Erro ao reconectar com ${peerId}: ${err}`);
    }
  };

  // Função para atualizar todas as chamadas existentes com um novo stream
  const updateExistingCallsWithNewStream = (stream: MediaStream) => {
    log(`PASSO 2.1: Atualizando ${connectionsRef.current.size} chamadas existentes com novo stream de áudio`);

    // Verificar se temos conexões
    if (connectionsRef.current.size === 0) {
      log('Nenhuma conexão existente para atualizar');
      return;
    }

    // Para cada conexão existente
    connectionsRef.current.forEach((call, peerId) => {
      try {
        // Verificar se a conexão ainda está ativa
        if (call.peerConnection &&
            call.peerConnection.connectionState !== 'closed' &&
            call.peerConnection.connectionState !== 'failed' &&
            typeof call.peerConnection.getSenders === 'function') {

          log(`Atualizando stream para peer ${peerId}`);

          // Obter todos os transmissores de áudio
          const audioSenders = call.peerConnection.getSenders().filter(sender =>
            sender.track && sender.track.kind === 'audio'
          );

          if (audioSenders.length > 0) {
            // Substituir a faixa de áudio em cada transmissor
            const audioTracks = stream.getAudioTracks();
            if (audioTracks.length > 0) {
              const audioTrack = audioTracks[0];

              // Substituir a faixa em todos os transmissores de áudio
              let trackReplaced = false;

              for (const sender of audioSenders) {
                try {
                  log(`Substituindo faixa de áudio para ${peerId}`);
                  sender.replaceTrack(audioTrack)
                    .then(() => {
                      trackReplaced = true;
                      log(`Faixa de áudio substituída com sucesso para ${peerId}`);
                    })
                    .catch((replaceErr) => {
                      log(`Erro ao substituir faixa para ${peerId}: ${replaceErr}`);
                    });
                } catch (replaceErr) {
                  log(`Erro ao substituir faixa para ${peerId}: ${replaceErr}`);
                }
              }

              // Se não conseguimos substituir nenhuma faixa, tentar reconectar
              if (!trackReplaced) {
                log(`Não foi possível substituir nenhuma faixa para ${peerId}, reconectando...`);
                reconnectWithPeer(peerId);
              }
            } else {
              log(`Nenhuma faixa de áudio no stream para ${peerId}, reconectando...`);
              reconnectWithPeer(peerId);
            }
          } else {
            log(`Nenhum transmissor de áudio encontrado para ${peerId}, reconectando...`);
            // Se não houver transmissores, tentar reconectar
            reconnectWithPeer(peerId);
          }
        } else {
          log(`Conexão com ${peerId} fechada ou não suporta substituição de stream, reconectando...`);
          // Se a conexão estiver fechada ou não suportar substituição, tentar reconectar
          reconnectWithPeer(peerId);
        }
      } catch (err) {
        log(`Erro ao atualizar stream para ${peerId}: ${err}`);
        // Tentar reconectar em caso de erro
        reconnectWithPeer(peerId);
      }
    });
  };

  // Função aprimorada para ativar/desativar o microfone
  const handleMicrophoneToggle = () => {
    if (isInitializing) {
      console.log('Inicialização em andamento, ignorando toggle do microfone');
      return;
    }

    const newMicEnabled = !micEnabled;
    console.log(`%cMICROFONE: ${newMicEnabled ? 'Ativando' : 'Desativando'} microfone...`, 'background: #e74c3c; color: white; padding: 2px 4px; border-radius: 2px;');
    setIsInitializing(true); // Indicar que está processando

    // Verificar se temos um stream local
    if (!localStreamRef.current) {
      console.log('Nenhum stream local disponível, tentando obter acesso ao microfone...');

      // Usar opções otimizadas para maior compatibilidade entre navegadores
      const audioConstraints = {
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          channelCount: 1, // Mono para melhor performance e compatibilidade
          sampleRate: 44100 // Taxa de amostragem padrão
        },
        video: false
      };

      // Verificar se o navegador suporta getUserMedia
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        console.error('Este navegador não suporta getUserMedia');
        alert('Seu navegador não suporta acesso ao microfone. Por favor, use um navegador moderno.');
        setIsInitializing(false);
        return;
      }

      navigator.mediaDevices.getUserMedia(audioConstraints)
        .then(stream => {
          console.log(`Microfone acessado com sucesso: ${stream.getAudioTracks().length} faixas de áudio`);

          // Verificar se o stream tem faixas de áudio
          if (stream.getAudioTracks().length === 0) {
            throw new Error('Nenhuma faixa de áudio disponível no stream');
          }

          // Definir o estado do stream de acordo com o estado desejado
          stream.getAudioTracks().forEach(track => {
            track.enabled = newMicEnabled;
            console.log(`%cFaixa de áudio ${newMicEnabled ? 'ATIVADA' : 'SILENCIADA'}: ${track.label}`, 'background: #9b59b6; color: white; padding: 2px 4px; border-radius: 2px;');

            // Verificar se a faixa está realmente habilitada
            console.log('Estado da faixa após alteração:', {
              id: track.id,
              label: track.label,
              enabled: track.enabled,
              muted: track.muted,
              readyState: track.readyState
            });
          });

          // Armazenar o stream
          localStreamRef.current = stream;

          // Configurar detecção de atividade de voz se o microfone estiver ativado
          if (newMicEnabled) {
            setupVoiceActivityDetection(stream);
          }

          // Chamar todos os peers disponíveis imediatamente
          // Isso garante que as conexões sejam estabelecidas mesmo com o microfone silenciado
          callAllPeers();

          // Atualizar o estado do microfone
          setMicEnabled(newMicEnabled);
          setIsInitializing(false);
          console.log(`Microfone inicializado e ${newMicEnabled ? 'ativado' : 'silenciado'} com sucesso`);
        })
        .catch(err => {
          console.error(`Erro ao acessar o microfone: ${err.name} - ${err.message}`);

          // Tratamento específico para diferentes tipos de erro
          if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
            alert('Acesso ao microfone negado. Por favor, permita o acesso ao microfone nas configurações do navegador.');
          } else if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
            alert('Nenhum microfone encontrado. Por favor, conecte um microfone e tente novamente.');
          } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
            alert('Seu microfone está sendo usado por outro aplicativo. Feche outros aplicativos e tente novamente.');
          } else {
            alert(`Erro ao acessar o microfone: ${err.message}. Tente recarregar a página.`);
          }

          setIsInitializing(false);
        });
      return;
    }

    // Temos um stream local, apenas alternar entre ativado/desativado
    try {
      if (newMicEnabled) {
        // Ativar microfone
        console.log('%cAtivando microfone existente...', 'background: #e74c3c; color: white; padding: 2px 4px; border-radius: 2px;');

        // Ativar as faixas de áudio
        localStreamRef.current.getAudioTracks().forEach(track => {
          track.enabled = true;
          console.log(`%cFaixa de áudio ATIVADA: ${track.label}`, 'background: #9b59b6; color: white; padding: 2px 4px; border-radius: 2px;');

          // Verificar se a faixa está realmente habilitada
          console.log('Estado da faixa após ativação:', {
            id: track.id,
            label: track.label,
            enabled: track.enabled,
            muted: track.muted,
            readyState: track.readyState
          });
        });

        // Configurar detecção de atividade de voz
        setupVoiceActivityDetection(localStreamRef.current);
      } else {
        // Desativar microfone (silenciar)
        console.log('%cDesativando microfone (silenciando)...', 'background: #e74c3c; color: white; padding: 2px 4px; border-radius: 2px;');

        // Notificar que parou de falar
        if (isSpeaking) {
          setIsSpeaking(false);
          onSpeakingChange?.(localPeerId, false);
        }

        // Silenciar as faixas de áudio
        localStreamRef.current.getAudioTracks().forEach(track => {
          track.enabled = false;
          console.log(`%cFaixa de áudio SILENCIADA: ${track.label}`, 'background: #9b59b6; color: white; padding: 2px 4px; border-radius: 2px;');

          // Verificar se a faixa está realmente desabilitada
          console.log('Estado da faixa após silenciamento:', {
            id: track.id,
            label: track.label,
            enabled: track.enabled,
            muted: track.muted,
            readyState: track.readyState
          });
        });

        // Limpar detecção de atividade de voz
        if (voiceDetectionIntervalRef.current) {
          clearInterval(voiceDetectionIntervalRef.current);
          voiceDetectionIntervalRef.current = null;
        }
      }

      // Atualizar o estado do microfone
      setMicEnabled(newMicEnabled);
      console.log(`Microfone ${newMicEnabled ? 'ativado' : 'desativado'} com sucesso`);

      // Notificar todas as conexões sobre a mudança de estado do microfone
      // Isso é importante para garantir que todos os peers recebam o estado atualizado
      updateExistingCallsWithNewStream(localStreamRef.current);

      // Verificar se temos conexões com todos os peers disponíveis
      // Isso garante que todos os peers estejam conectados, mesmo que o microfone esteja silenciado
      callAllPeers();

      setIsInitializing(false);
    } catch (err) {
      console.error(`Erro ao ${newMicEnabled ? 'ativar' : 'desativar'} microfone: ${err}`);

      // Tentar recuperar de erros
      try {
        // Se houver erro, tentar reinicializar o stream
        if (localStreamRef.current) {
          // Parar todas as faixas atuais
          localStreamRef.current.getTracks().forEach(track => track.stop());

          // Obter um novo stream
          navigator.mediaDevices.getUserMedia({ audio: true, video: false })
            .then(newStream => {
              console.log('Obtido novo stream de áudio após erro');

              // Configurar o novo stream
              newStream.getAudioTracks().forEach(track => {
                track.enabled = newMicEnabled;
              });

              // Substituir o stream antigo
              localStreamRef.current = newStream;

              // Atualizar todas as conexões
              updateExistingCallsWithNewStream(newStream);

              // Atualizar estado
              setMicEnabled(newMicEnabled);
              setIsInitializing(false);
            })
            .catch(recoveryErr => {
              console.error(`Erro ao recuperar de falha do microfone: ${recoveryErr}`);
              setIsInitializing(false);
            });
        } else {
          setIsInitializing(false);
        }
      } catch (recoveryErr) {
        console.error(`Erro ao tentar recuperar de falha do microfone: ${recoveryErr}`);
        setIsInitializing(false);
      }
    }
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
      {/* Botão de microfone com animação de inicialização */}
      <button
        onClick={handleMicrophoneToggle}
        disabled={isInitializing}
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: micEnabled ? '#4CAF50' : (isInitializing ? '#999' : '#333'),
          color: 'white',
          border: 'none',
          cursor: isInitializing ? 'wait' : 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '20px',
          position: 'relative',
          overflow: 'hidden'
        }}
        title={isInitializing ? "Inicializando microfone..." : (micEnabled ? "Desativar microfone" : "Ativar microfone")}
      >
        {isInitializing ? (
          <>
            <span style={{ opacity: 0.5 }}>🎤</span>
            <div style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '3px',
              backgroundColor: '#4CAF50',
              animation: 'progress 2s infinite linear'
            }} />
            <style>{`
              @keyframes progress {
                0% { width: 0; }
                100% { width: 100%; }
              }
            `}</style>
          </>
        ) : (micEnabled ? '🎤' : '🎤')}
      </button>

      {/* Botão de áudio */}
      <button
        onClick={() => {
          const newAudioEnabled = !audioEnabled;
          console.log(`ÁUDIO: ${newAudioEnabled ? 'Ativando' : 'Desativando'} áudio para ${audioElementsRef.current.size} conexões...`);

          // Atualizar o estado
          setAudioEnabled(newAudioEnabled);

          // Aplicar imediatamente a mudança a todos os elementos de áudio
          audioElementsRef.current.forEach((audio, peerId) => {
            try {
              audio.muted = !newAudioEnabled;
              console.log(`Áudio para peer ${peerId} ${newAudioEnabled ? 'ativado' : 'desativado'}`);

              // Tentar reproduzir se estiver pausado
              if (audio.paused && audio.srcObject && newAudioEnabled) {
                console.log(`Tentando reproduzir áudio pausado para peer ${peerId}`);

                // Tentar reproduzir com tratamento de erro robusto
                const playPromise = audio.play();

                if (playPromise !== undefined) {
                  playPromise.catch(err => {
                    console.error(`Erro ao reproduzir áudio para peer ${peerId}: ${err}`);

                    // Tentar novamente com um pequeno atraso
                    setTimeout(() => {
                      if (audio.paused && audio.srcObject) {
                        console.log(`Segunda tentativa de reproduzir áudio para peer ${peerId}`);

                        // Recriar o elemento de áudio como última tentativa
                        try {
                          const srcObject = audio.srcObject;
                          const parentNode = audio.parentNode;

                          // Remover o elemento antigo
                          audio.pause();
                          audio.srcObject = null;

                          // Criar um novo elemento
                          const newAudio = document.createElement('audio');
                          newAudio.srcObject = srcObject;
                          newAudio.autoplay = true;
                          newAudio.muted = !newAudioEnabled;
                          newAudio.setAttribute('data-peer-id', peerId);

                          // Substituir o elemento antigo
                          if (parentNode) {
                            parentNode.replaceChild(newAudio, audio);
                          } else {
                            document.body.appendChild(newAudio);
                          }

                          // Atualizar a referência
                          audioElementsRef.current.set(peerId, newAudio);

                          console.log(`Elemento de áudio recriado para peer ${peerId}`);
                        } catch (recreateErr) {
                          console.error(`Erro ao recriar elemento de áudio: ${recreateErr}`);
                        }
                      }
                    }, 1000);
                  });
                }
              }
            } catch (err) {
              console.error(`Erro ao atualizar estado de áudio para peer ${peerId}: ${err}`);
            }
          });

          // Verificar se temos conexões com todos os peers disponíveis
          callAllPeers();
        }}
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: audioEnabled ? '#4CAF50' : '#333',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '20px'
        }}
        title={audioEnabled ? "Desativar áudio" : "Ativar áudio"}
      >
        {audioEnabled ? '🔊' : '🔇'}
      </button>
    </div>
  );
};

export default SimpleAudioControls;
