import './App.css'
import ThreeJSRenderer from './components/ThreeJSRenderer'
import { useState, useCallback } from 'react'
import Chat from './components/Chat'
import { LanguageProvider } from './i18n/LanguageContext'
import HeaderControls from './components/HeaderControls'
import { useLanguage } from './i18n/LanguageContext'

// Test data array for bitmap visualization - 282 transactions (indices 0-281)
const testData = [5,6,6,5,6,5,7,5,5,6,6,8,5,5,5,5,6,5,4,6,5,5,5,5,5,6,5,5,5,5,5,7,5,7,5,5,5,5,5,5,5,5,5,4,5,5,5,5,5,5,5,4,5,5,4,5,5,5,5,4,4,5,4,6,4,5,4,5,5,4,4,4,5,5,6,5,5,4,4,6,5,4,5,5,5,4,5,4,5,5,4,4,4,4,4,3,4,3,3,3,5,5,4,3,3,3,2,3,4,3,5,4,3,3,3,4,3,4,5,2,4,5,5,5,5,5,4,5,4,4,4,4,3,4,3,5,5,4,5,4,4,3,5,4,4,5,5,5,5,3,4,5,5,3,4,4,5,2,3,5,4,5,5,5,5,4,4,3,4,4,3,4,4,3,3,3,4,3,4,3,4,4,4,4,4,3,3,4,3,4,4,4,4,4,4,4,4,3,4,4,4,4,4,3,3,3,4,4,4,4,4,3,3,3,3,4,3,3,4,3,3,4,3,4,1,3,3,3,4,2,4,3,4,5,2,3,4,3,3,3,4,3,3,4,3,2,2,2,3,3,2,2,3,3,2,2,2,4,3,3,3,1,3,2,1,1,1,1,1,4,2,3,1,2,1,2,4,2,2,8,2,2];

// App content component that uses translations
const AppContent = () => {
  const { t } = useLanguage();
  const [searchValue, setSearchValue] = useState('');
  const [selectedBlock, setSelectedBlock] = useState<number | null>(null);
  const [currentParcel, setCurrentParcel] = useState<number | null>(null);
  const [isChatOpen, setIsChatOpen] = useState(false);

  // Handle search input change - now only updates the input value
  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
  }, []);

  // Handle search form submission
  const handleSearchSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const blockNumber = parseInt(searchValue);
    if (!isNaN(blockNumber) && blockNumber >= 0 && blockNumber <= 281) {
      console.log('Selecting block:', blockNumber);
      setSelectedBlock(blockNumber);
      // Clear the search input after successful search
      setSearchValue('');
    }
  }, [searchValue]);

  // Toggle chat panel
  const toggleChat = useCallback(() => {
    setIsChatOpen(prev => !prev);
  }, []);

  return (
    <div className="App" style={{ height: '100vh', overflow: 'hidden' }}>
      {/* Main content */}
      <div style={{ 
        width: '100%',
        height: '100%',
        position: 'relative',
        background: '#000'
      }}>
        {/* Header controls */}
        <HeaderControls />

        {/* Current parcel text - more subtle styling */}
        {currentParcel !== null && (
          <div style={{
            position: 'absolute',
            top: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: 'rgba(255, 255, 255, 0.6)', // More transparent text
            fontSize: '14px', // Slightly smaller
            padding: '6px 12px', // Smaller padding
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, 0.4)', // More transparent background
            border: '1px solid rgba(138, 43, 226, 0.15)', // More subtle border
            backdropFilter: 'blur(5px)',
            zIndex: 3,
            whiteSpace: 'nowrap',
            letterSpacing: '0.5px', // Better readability
            fontWeight: '300', // Lighter font weight
            transition: 'opacity 0.3s ease', // Smooth transition
            opacity: 0.8
          }}>
            {`${t('currentParcel')}${currentParcel + 1}`}
          </div>
        )}

        {/* Search form overlay */}
        <div style={{ 
          position: 'absolute', 
          top: '70px',
          left: '50%', 
          transform: 'translateX(-50%)',
          zIndex: 2
        }}>
          <form onSubmit={handleSearchSubmit} style={{ display: 'flex', gap: '8px' }}>
            <input
              type="number"
              value={searchValue}
              onChange={handleSearchChange}
              placeholder={t('searchPlaceholder')}
              min="0"
              max="281"
              style={{
                padding: '6px 12px',
                borderRadius: '4px',
                border: '1px solid rgba(138, 43, 226, 0.15)',
                width: '180px',
                fontSize: '14px',
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                color: 'rgba(255, 255, 255, 0.8)',
                backdropFilter: 'blur(5px)',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                outline: 'none',
                transition: 'all 0.3s ease'
              }}
            />
            <button
              type="submit"
              style={{
                padding: '6px 12px',
                borderRadius: '4px',
                border: '1px solid rgba(138, 43, 226, 0.15)',
                backgroundColor: 'rgba(138, 43, 226, 0.4)',
                color: 'rgba(255, 255, 255, 0.8)',
                fontSize: '14px',
                cursor: 'pointer',
                backdropFilter: 'blur(5px)',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.3s ease'
              }}
            >
              {t('searchButton')}
            </button>
          </form>
        </div>
        
        {/* Full screen 3D renderer */}
        <ThreeJSRenderer 
          data={testData}
          style={{ 
            width: '100%', 
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1
          }}
          selectedBlock={selectedBlock}
          onCurrentParcel={setCurrentParcel}
        />

        {/* Footer */}
        <div style={{
          position: 'fixed',
          bottom: '20px',
          left: '20px',
          color: 'rgba(255, 255, 255, 0.7)',
          fontSize: '14px',
          padding: '8px 16px',
          borderRadius: '4px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          border: '1px solid rgba(138, 43, 226, 0.2)',
          backdropFilter: 'blur(5px)',
          zIndex: 3,
          letterSpacing: '0.5px',
          fontFamily: 'monospace'
        }}>
          203779.bitmap - Multiverso
        </div>

        {/* Chat toggle button */}
        <button
          onClick={toggleChat}
          style={{
            position: 'fixed',
            bottom: '20px',
            right: isChatOpen ? '320px' : '20px',
            zIndex: 3,
            padding: '12px',
            borderRadius: '50%',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            border: '1px solid rgba(138, 43, 226, 0.3)',
            color: 'rgba(138, 43, 226, 0.9)',
            cursor: 'pointer',
            width: '50px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
            transition: 'all 0.3s ease'
          }}
        >
          {isChatOpen ? '✕' : '💬'}
        </button>

        {/* Floating chat panel */}
        <div style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          width: '300px',
          height: '400px',
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          borderRadius: '12px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.4)',
          transform: `translateX(${isChatOpen ? '0' : 'calc(100% + 20px)'})`,
          transition: 'transform 0.3s ease',
          zIndex: 2,
          overflow: 'hidden',
          border: '1px solid rgba(255, 255, 255, 0.1)'
        }}>
          <Chat />
        </div>
      </div>
    </div>
  );
};

// Main App component with LanguageProvider
function App() {
  return (
    <LanguageProvider>
      <AppContent />
    </LanguageProvider>
  );
}

export default App;
